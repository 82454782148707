export default {
  name: 'CentricOS Menu',
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    isCentricOSIntegrated: () => (station) =>
      Boolean(station?.is?.cafe360 && station?.meta.menu?.brand),
  },
  actions: {
    async fetchLocalBrand(state, { localBrandId, relationships }) {
      try {
        let brandUrl = `/menu/v3/brand/${localBrandId}`;
        if (relationships) {
          brandUrl += `?relationships=[${relationships.map((r) => `"${r}"`).join(',')}]`;
        }
        const { data } = await this._vm.api.get(brandUrl);

        return data;
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Could not fetch CentricOS Local Brand');
        return undefined;
      }
    },
    async fetchCentricOSMenusByName(state, name) {
      try {
        const { data } = await this._vm.api.get(`/menu/v3/local-menu-groups`, {
          params: {
            filter: { name: { $contains: name } },
            sort_by: 'name',
            sort_order: 'ASC',
            limit: 15,
          },
        });

        return data.results;
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Could not fetch CentricOS Menus');
        return [];
      }
    },
    async fetchMenuLocalBrandsByName(state, { menu_id, name }) {
      try {
        const { data } = await this._vm.api.get(`/menu/v3/local-menu-group/${menu_id}/brands`, {
          params: {
            filter: { name: { $contains: name || '' } },
            sort_by: 'name',
            sort_order: 'ASC',
            limit: 15,
          },
        });

        return data.results;
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Could not fetch CentricOS Menu Local Brands');
        return [];
      }
    },

    async fetchLocalBrandViews(state, localBrandId) {
      try {
        const { data } = await this._vm.api.get(`/menu/v3/brand/${localBrandId}/menus`);
        return data.results;
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Could not fetch CentricOS Local Brand');
        return [];
      }
    },

    async fetchLocalBrandV2Menus({ dispatch }, localBrandId) {
      try {
        const centricOSMenus = await dispatch('fetchLocalBrandViews', localBrandId);
        return centricOSMenus
          .map((menu) => {
            return {
              label: {
                en: menu.name,
              },
              id: menu.id,
            };
          })
          .filter(Boolean);
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Could not fetch CentricOS Local Brand');
        return [];
      }
    },
    async getMenuGlobalMenuGroups(_, params) {
      const {
        data: { results },
      } = await this._vm.api.get(`/menu/v3/global-menu-groups`, {
        params,
      });
      return results;
    },
    async getMenuLocalMenuGroups(_, params) {
      const {
        data: { results },
      } = await this._vm.api.get(`/menu/v3/local-menu-groups`, {
        params,
      });
      return results;
    },
    async getMenuGlobalMenuGroup(_, { id, params }) {
      const { data } = await this._vm.api.get(`/menu/v3/global-menu-group/${id}`, {
        params,
      });
      return data;
    },
    async getMenuLocalMenuGroup(_, { id, params }) {
      const { data } = await this._vm.api.get(`/menu/v3/local-menu-group/${id}`, {
        params,
      });
      return data;
    },
    async getMenuBrand(_, { id, params }) {
      const { data } = await this._vm.api.get(`/menu/v3/brand/${id}`, {
        params,
      });
      return data;
    },
    async getSitesLinkedLocalMenuGroups(store, id) {
      const { data } = await this._vm.api.get(`/menu/v3/local-menu-group/${id}/site`);
      return data?.results ?? [];
    },
    async linkSiteToLocalMenuGroups(store, payload) {
      await this._vm.api.post(`/menu/v3/local-menu-groups/site-groups`, payload);
    },
    async removeSiteToLocalMenuGroupsLink(store, payload) {
      await this._vm.api.delete(`/menu/v3/local-menu-groups/site-groups`, { data: payload });
    },
  },
};
