<template>
  <v-layout
    align-center
    v-bind:class="{ [stylingClass]: true, [`justify-${position}`]: true }"
    :style="clickAble"
  >
    <div
      v-bind:class="{
        [style.OptionLeftNotSelected]: value !== leftValue,
        [style.OptionLeftSelected]: value === leftValue,
        [style.disabledSelected]: disabled && value === leftValue,
        [style.disabledNotSelected]: disabled && value !== leftValue,
      }"
      v-on:click="onClick('left')"
    >
      <div
        v-bind:class="{
          'Subtitle-2-White-High-Emphasis-Center': value === leftValue,
          'Subtitle-2-Black-Medium-Emphasis-Center': value !== leftValue,
        }"
      >
        {{ leftText }}
      </div>
    </div>
    <div
      v-bind:class="{
        [style.OptionRightNotSelected]: value !== rightValue,
        [style.OptionRightSelected]: value === rightValue,
        [style.disabledSelected]: disabled && value === rightValue,
        [style.disabledNotSelected]: disabled && value !== rightValue,
      }"
      v-on:click="onClick('right')"
    >
      <div
        v-bind:class="{
          'Subtitle-2-White-High-Emphasis-Center': value === rightValue,
          'Subtitle-2-Black-Medium-Emphasis-Center': value !== rightValue,
        }"
      >
        {{ rightText }}
      </div>
    </div>
  </v-layout>
</template>

<script>
// add new style here following same pattern as Blue-White
const TOGGLE_STYLES = {
  'Blue-White': {
    OptionLeftSelected: 'Option-Left-Selected-Blue-White',
    OptionLeftNotSelected: 'Option-Left-Not-Selected-Blue-White',
    OptionRightSelected: 'Option-Right-Selected-Blue-White',
    OptionRightNotSelected: 'Option-Right-Not-Selected-Blue-White',
    disabledSelected: 'Option-Toggle-Disabled-Selected',
    disabledNotSelected: 'Option-Toggle-Disabled',
  },
  'Blue-red': {
    OptionLeftSelected: 'Option-Left-Selected-Blue-Red',
    OptionLeftNotSelected: 'Option-Left-Not-Selected-Blue-Red',
    OptionRightSelected: 'Option-Right-Selected-Blue-Red',
    OptionRightNotSelected: 'Option-Right-Not-Selected-Blue-Red',
    disabledSelected: 'Option-Toggle-Disabled-Selected',
    disabledNotSelected: 'Option-Toggle-Disabled',
  },
};

export default {
  props: {
    type: {
      default: 'Blue-White',
    },
    leftValue: {
      default: true,
    },
    leftText: {
      default: 'Yes',
    },
    rightValue: {
      default: false,
    },
    rightText: {
      default: 'No',
    },
    onClickHandler: {
      default: null,
    },
    position: {
      default: 'center',
    },
    stylingClass: {
      default: 'my-4',
    },
    value: {
      default: false,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    style: {
      OptionLeftSelected: 'Option-Left-Selected-Blue-White',
      OptionLeftNotSelected: 'Option-Left-Not-Selected-Blue-White',
      OptionRightSelected: 'Option-Right-Selected-Blue-White',
      OptionRightNotSelected: 'Option-Right-Not-Selected-Blue-White',
    },
  }),
  mounted() {
    this.style = TOGGLE_STYLES[this.type];
    if (this.value === null) this.$emit('input', false);
  },
  methods: {
    select(optionValue) {
      if (typeof this.onClickHandler === 'function') {
        this.onClickHandler(optionValue);
      }
      this.$emit('input', optionValue);
    },
    onClick(side) {
      const { disabled, value, select } = this;
      const sideValue = this[`${side}Value`];

      return !disabled && value !== sideValue && select(sideValue);
    },
  },
  computed: {
    clickAble() {
      const css = {
        cursor: 'pointer',
      };
      if (this.disabled) {
        css.cursor = 'not-allowed';
      }
      return css;
    },
  },
};
</script>

<style>
/*
    Blue White style option
*/
.Option-Left-Selected-Blue-White {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 71px;
  height: 36px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #1c7bda;
}

.Option-Left-Not-Selected-Blue-White {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 73px;
  height: 36px;
  border: solid 1px #1c7bda;
  background-color: #fafafa;
}

.Option-Right-Selected-Blue-White {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 71px;
  height: 36px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #1c7bda;
}

.Option-Right-Not-Selected-Blue-White {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 73px;
  height: 36px;
  border: solid 1px #1c7bda;
  background-color: #fafafa;
}
/*
  Blue red system option
*/

.Option-Left-Selected-Blue-Red {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 71px;
  height: 36px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #1c7bda;
  background-color: #1c7bda;
}

.Option-Left-Not-Selected-Blue-Red {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 73px;
  height: 36px;
  border: solid 1px #ea526f;
  background-color: #fafafa;
}

.Option-Right-Selected-Blue-Red {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 71px;
  height: 36px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #ea526f;
}

.Option-Right-Not-Selected-Blue-Red {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 73px;
  height: 36px;
  border: solid 1px #1c7bda;
  background-color: #fafafa;
}
.Option-Toggle-Disabled-Selected {
  background-color: #888888;
}
.Option-Toggle-Disabled {
  border-color: #888888;
}
</style>
