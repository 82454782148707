import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _vm.user
            ? _c(
                VFlex,
                {
                  staticStyle: { display: "flex" },
                  attrs: {
                    md12: "",
                    "align-center": "",
                    "justify-space-between": ""
                  }
                },
                [
                  _c("div", { staticClass: "H4-Primary-Left" }, [
                    _vm._v("Hey There " + _vm._s(_vm.user.name.first) + ".")
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end align-center" },
                    [
                      _vm.selectedSite.style && _vm.selectedSite.style.logo
                        ? _c("img", {
                            staticClass: "site_logo",
                            attrs: {
                              src: _vm.selectedSite.style.logo,
                              alt: _vm.selectedSite.label.en + " logo"
                            }
                          })
                        : _vm._e(),
                      _vm.sitesAvailable.length > 1
                        ? _c(VSelect, {
                            staticStyle: { "max-width": "333px" },
                            attrs: {
                              items: _vm.sitesAvailable,
                              value: _vm.selectedSite.id
                            },
                            on: { change: _vm.selectSite }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "loading-wrapper",
            { attrs: { loading: _vm.loading, mt: "20vh", size: 100 } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VCard,
                    [
                      _c(
                        VCardTitle,
                        {
                          staticClass:
                            "H6-Selected-On-Surface-High-Emphasis-Left pl-3"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedSite.parentMultigroup.name) +
                              " at " +
                              _vm._s(_vm.selectedSite.label.en) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        VCardText,
                        { staticClass: "Body-1-Black-High-Emphasis-Left pl-3" },
                        [
                          _c("p", [
                            _vm._v(
                              "Use the Admin Panel to help you manage your mobile ordering application!"
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Start by clicking on the side bar and navigate through the available features."
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n              Need Help? Visit the CDL Digital Support Center,\n              "
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: { "text-decoration": "none" },
                                attrs: { href: "https://www.cdlsupport.io" }
                              },
                              [_vm._v("CDLsupport.io")]
                            ),
                            _vm._v(
                              "\n              to find resource materials or submit a ticket to Digital Care.\n            "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VCard,
                    [
                      _c(
                        VCardTitle,
                        {
                          staticClass:
                            "H6-Selected-On-Surface-High-Emphasis-Left pl-3"
                        },
                        [_vm._v("\n            Station List\n          ")]
                      ),
                      _c(
                        VFlex,
                        { attrs: { xs7: "", "ml-2": "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.searchLabel,
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: ""
                            },
                            model: {
                              value: _vm.searchField,
                              callback: function($$v) {
                                _vm.searchField = $$v
                              },
                              expression: "searchField"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            VDataTable,
                            {
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.availableBrands,
                                search: _vm.searchField,
                                "hide-actions": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function($event) {
                                                return _vm.goToBrand(props.item)
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(props.item.name))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function($event) {
                                                return _vm.goToBrand(props.item)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  props.item
                                                    .location_description
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _c("td", [
                                          _c("div", [
                                            _vm._v(
                                              "\n                      Pickup:\n                      "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "Body-1-Black-High-Emphasis-Left",
                                                style: _vm.showBoldedHours(
                                                  props.item.pickupDeliveryHours
                                                    .pickupTimes
                                                )
                                                  ? "font-weight: bold"
                                                  : ""
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goToBrand({
                                                          id: props.item.id,
                                                          name: props.item.name,
                                                          tab: "pickup"
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          props.item
                                                            .pickupDeliveryHours
                                                            .pickupTimes
                                                        ) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          props.item.pickupDeliveryHours
                                            .deliveryTimes
                                            ? _c(
                                                "div",
                                                { staticClass: "clickable" },
                                                [
                                                  _vm._v(
                                                    "\n                      Delivery:\n                      "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "Body-1-Black-High-Emphasis-Left",
                                                      style: _vm.showBoldedHours(
                                                        props.item
                                                          .pickupDeliveryHours
                                                          .deliveryTimes
                                                      )
                                                        ? "font-weight: bold"
                                                        : ""
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.goToBrand(
                                                                {
                                                                  id:
                                                                    props.item
                                                                      .id,
                                                                  name:
                                                                    props.item
                                                                      .name,
                                                                  tab:
                                                                    "delivery"
                                                                }
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                props.item
                                                                  .pickupDeliveryHours
                                                                  .deliveryTimes
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          props.item.brand_status &&
                                          props.item.brand_status.is_closed
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Temporarily-closed"
                                                },
                                                [
                                                  _c(VImg, {
                                                    staticClass: "svg-logo",
                                                    attrs: {
                                                      src: require("@/assets/store-front.svg")
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        props.item
                                                          .reopen_message
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]),
                                        _vm.showMenuColumn
                                          ? _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  padding: "0 !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    staticClass: "ma-0",
                                                    attrs: {
                                                      disabled: !props.item
                                                        .brandMenusLink,
                                                      outline: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goToBrandMenus(
                                                          props.item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          props.item
                                                            .brandMenusLink
                                                            ? "VIEW MENU"
                                                            : "MENU UNAVAILABLE"
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(VProgressLinear, {
                                attrs: {
                                  color: "blue",
                                  indeterminate: "",
                                  height: "7"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "progress",
                                    fn: function() {
                                      return undefined
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }