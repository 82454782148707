import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.show
    ? _c(
        VLayout,
        { attrs: { wrap: "", "justify-center": "", "align-center": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VImg, {
                attrs: {
                  src: require("@/assets/construction.svg"),
                  height: "40vh",
                  contain: ""
                }
              })
            ],
            1
          ),
          _c(VFlex, { attrs: { shrink: "" } }, [
            _c("span", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
              _vm._v(_vm._s(_vm.message))
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }