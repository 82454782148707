import axios from 'axios';
import Vue from 'vue';
import router from '../router';
import store from '../store';

const endpoints = {
  dev: 'https://dev.api.compassdigital.org/',
  staging: 'https://staging.api.compassdigital.org/',
  production: 'https://api.compassdigital.org/',
};

// determine base url
const baseURL = (() => {
  switch (document.location.hostname) {
    case 'adminpanel.compassdigital.org':
      return endpoints.production;
    case 'staging.adminpanel.compassdigital.org':
      return endpoints.staging;
    default:
      return endpoints.dev;
  }
})();

// Create custom axios instance for easy api reference
const api = axios.create({
  baseURL,
});
// REQUEST HANDLER
api.interceptors.request.use(
  async (req) => {
    if (req.url?.startsWith('/user/auth')) return req;
    const token = await store.dispatch('adminPanel/getValidAccessToken');
    if (token) {
      if (!req.headers.authorization) {
        req.headers.authorization = `Bearer ${token}`;
      }
      req.headers['content-type'] = 'application/json';
    }

    return req;
  },
  (error) => Promise.reject(error),
);

// RESPONSE HANDLER
api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch('adminPanel/setLogout');
      store.commit('sites/clear');
      store.commit(
        'adminPanel/setExitStatus',
        'Your login has expired, please login again to continue.',
      );
      router.push({ name: 'login' });
    }

    if (error.response?.data?.code === 501) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('There are too many orders in the selected range.');
    }

    return Promise.reject(error);
  },
);

Vue.prototype.api = api;
export default api;
export { baseURL };
