import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    {
      class:
        ((_obj = {}),
        (_obj[_vm.stylingClass] = true),
        (_obj["justify-" + _vm.position] = true),
        _obj),
      style: _vm.clickAble,
      attrs: { "align-center": "" }
    },
    [
      _c(
        "div",
        {
          class:
            ((_obj$1 = {}),
            (_obj$1[_vm.style.OptionLeftNotSelected] =
              _vm.value !== _vm.leftValue),
            (_obj$1[_vm.style.OptionLeftSelected] =
              _vm.value === _vm.leftValue),
            (_obj$1[_vm.style.disabledSelected] =
              _vm.disabled && _vm.value === _vm.leftValue),
            (_obj$1[_vm.style.disabledNotSelected] =
              _vm.disabled && _vm.value !== _vm.leftValue),
            _obj$1),
          on: {
            click: function($event) {
              return _vm.onClick("left")
            }
          }
        },
        [
          _c(
            "div",
            {
              class: {
                "Subtitle-2-White-High-Emphasis-Center":
                  _vm.value === _vm.leftValue,
                "Subtitle-2-Black-Medium-Emphasis-Center":
                  _vm.value !== _vm.leftValue
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.leftText) + "\n    ")]
          )
        ]
      ),
      _c(
        "div",
        {
          class:
            ((_obj$2 = {}),
            (_obj$2[_vm.style.OptionRightNotSelected] =
              _vm.value !== _vm.rightValue),
            (_obj$2[_vm.style.OptionRightSelected] =
              _vm.value === _vm.rightValue),
            (_obj$2[_vm.style.disabledSelected] =
              _vm.disabled && _vm.value === _vm.rightValue),
            (_obj$2[_vm.style.disabledNotSelected] =
              _vm.disabled && _vm.value !== _vm.rightValue),
            _obj$2),
          on: {
            click: function($event) {
              return _vm.onClick("right")
            }
          }
        },
        [
          _c(
            "div",
            {
              class: {
                "Subtitle-2-White-High-Emphasis-Center":
                  _vm.value === _vm.rightValue,
                "Subtitle-2-Black-Medium-Emphasis-Center":
                  _vm.value !== _vm.rightValue
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.rightText) + "\n    ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }