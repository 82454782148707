import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import pako from 'pako';
import buffer from 'buffer';
import { mergeRecursive, deepFreeze } from '@/helpers';

const zip = (payload) => buffer.Buffer.from(pako.gzip(JSON.stringify(payload))).toString('base64');
export default {
  namespaced: true,
  state: {
    isLocal: false,
    isMenuValid: true,
    companiesFetchedMap: {},
    sectorsFetchedMap: {},
    menus: {},
    readOnlyMode: true,
    sessionLength: 30, // In minutes,
    showPluField: true,
    showPromoExemptField: true,
    showMEQField: false,
    companyGlobalModGroups: [],
    globalModGroup: {},
    menuVersion: 1,
    pageYOffset: 0,
    frictionlessStores: [],
  },

  mutations: {
    setReadOnlyMode(state, value) {
      state.readOnlyMode = value;
    },
    setLocal(state, value) {
      state.isLocal = value;
    },
    setIsMenuValid(state, value) {
      state.isMenuValid = value;
    },
    setMenu(state, { id, menu }) {
      this._vm.$set(state.menus, id, deepFreeze(menu));
    },
    setMenus(state, menus = []) {
      const newStateMenus = cloneDeep(state.menus);
      menus.forEach((menu) => {
        newStateMenus[menu.id] = newStateMenus[menu.id]
          ? Object.assign(newStateMenus[menu.id], menu)
          : menu;
      });
      Object.values(newStateMenus).forEach(deepFreeze);
      state.menus = newStateMenus;
    },
    removeMenu(state, { id }) {
      this._vm.$delete(state.menus, id);
    },
    setCompanyFetched(state, { id, value }) {
      state.companiesFetchedMap[id] = value;
    },
    setSectorFetched(state, { id, value }) {
      state.sectorsFetchedMap[id] = value;
    },
    setShowPluField(state, value) {
      state.showPluField = value;
    },
    setShowPromoExemptField(state, value) {
      state.showPromoExemptField = value;
    },
    setShowMEQField(state, value) {
      state.showMEQField = value;
    },
    setCompanyGlobalModGroups(state, globalMods = []) {
      Object.values(globalMods).forEach(deepFreeze);
      state.companyGlobalModGroups = globalMods;
    },
    setMenuVersion(state, value) {
      state.menuVersion = value;
    },
    setGlobalModGroup(state, { id, value }) {
      const globalModGroups = cloneDeep(state.companyGlobalModGroups);

      const found = globalModGroups.find((mg) => mg.id === id);
      if (found) {
        Object.assign(found, value);
      } else {
        globalModGroups.push(value);
      }

      state.companyGlobalModGroups = cloneDeep(globalModGroups);
    },
    setPageYOffset(state, value) {
      state.pageYOffset = value;
    },
    setAvailableSectors(state, value) {
      state.availableSectors = value;
    },
    setFrictionlessLocationStores(state, payload) {
      const frictionlessStores = [];
      // eslint-disable-next-line no-unused-vars
      for (const [key, obj] of Object.entries(payload)) {
        frictionlessStores.push(obj);
      }
      state.frictionlessStores = frictionlessStores;
    },
  },
  actions: {
    async fetchMenu(
      { commit, state },
      {
        id,
        nocache,
        noLocalCache = false,
        _query = undefined,
        refreshCache = false,
        show_unlinked = false,
        partial = false,
      },
    ) {
      const url = partial ? `/menu/partial/${id}/groups` : `/menu/${id}`;
      const stateMenu = state.menus[id];
      if (stateMenu && stateMenu.groups && !nocache && !noLocalCache) {
        return cloneDeep(state.menus[id]);
      }
      if (refreshCache) {
        // refreshing cache since with/without `extended` are two different caches
        // no await needed
        this._vm.api
          .get(url, {
            params: { nocache: true },
          })
          .catch((err) => {
            console.error(`Failed to update menu cache for id ${id}`, err);
          });
      }
      const res = await this._vm.api
        .get(url, {
          params: {
            nocache,
            extended: true,
            _query,
            show_unlinked,
          },
        })
        .catch((err) => {
          commit('setMenu', { id, undefined });
          throw err;
        });
      let dataToSave = res.data;
      if (state.menus[id] && _query) {
        dataToSave = mergeRecursive(cloneDeep(state.menus[id]), dataToSave);
      }
      commit('setMenu', { id, menu: dataToSave });

      return cloneDeep(state.menus[id]);
    },
    async fetchMenuGroupItems(
      { state, commit, dispatch },
      { menu_id, group_id, include_options, nocache, show_unlinked = false, _query } = {},
    ) {
      if (!menu_id || !group_id) return [];
      const menu =
        cloneDeep(state.menus?.[menu_id]) ||
        (await dispatch('fetchMenu', { id: menu_id, partial: true }));

      const group = menu.groups.find((_group) => _group.id === group_id);
      if (!group.items || !group.items.length) {
        try {
          group.items = (
            await this._vm.api.get(`/menu/partial/${menu_id}/group/${group_id}/items`, {
              params: {
                include_options,
                nocache,
                extended: true,
                _query,
                show_unlinked,
              },
            })
          ).data.items;
        } catch (error) {
          console.error(`Failed to fetch items for group ${group_id}`, error);
        }
      }
      commit('setMenu', { id: menu_id, menu });
      return group.items;
    },
    async fetchMenuItemModGroups(
      { commit, state },
      { menu_id, group_id, item_id, nocache, show_unlinked },
    ) {
      if (!menu_id || !group_id || !item_id) return [];
      const res = await this._vm.api.post(
        `/menu/partial/${menu_id}/group/${group_id}/item/options`,
        {
          item_id,
        },
        {
          params: {
            nocache,
            extended: true,
            show_unlinked,
          },
        },
      );
      const { items: partial_items } = res.data;
      const modifiedMenu = cloneDeep(state.menus[menu_id]);
      const group = modifiedMenu.groups.find((_group) => _group.id === group_id);
      for (const { id, options } of partial_items) {
        const item = group.items.find((_item) => _item.id === id);
        if (item) item.options = options;
      }
      commit('setMenu', { id: menu_id, menu: modifiedMenu });

      return partial_items;
    },
    async fetchFullCategory(
      { state, dispatch },
      { menu_id, group_id, category, is_partial_items } = {},
    ) {
      if (!menu_id || (!category && !group_id)) return {};

      const full_category = category
        ? cloneDeep(category)
        : cloneDeep(state.menus?.[menu_id]) ||
          (await dispatch('fetchMenu', { id: menu_id, partial: true }));
      if (category.items?.length && is_partial_items) {
        const missing_items = category.items.filter((item) => !item.options.length);
        if (missing_items.length) {
          const options = await dispatch('fetchMenuItemModGroups', {
            menu_id,
            group_id: category.id,
            item_id: missing_items.map((item) => item.id),
          });
          const options_dict = options.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur.options }),
            {},
          );
          full_category.items.forEach((item) => {
            if (!item.options.length) item.options = options_dict[item.id];
          });
        }
      } else {
        full_category.items = cloneDeep(
          await dispatch('fetchMenuGroupItems', {
            menu_id,
            group_id: category.id,
            include_options: true,
          }),
        );
      }
      return full_category;
    },
    async postMenu({ dispatch }, menu) {
      const { data } = await this._vm.api.post(`/menu`, zip(menu));
      dispatch('fetchMenu', { id: data.id, nocache: true }) // to refresh local cache
        .catch(() => console.error(`Could not update local cache for menu ${data.id}`));
      return data;
    },
    async putMenu(store, menu) {
      const { data } = await this._vm.api.put(`/menu/${menu.id}`, zip(menu), {
        params: { _query: '{id}' },
      });
      return data;
    },
    async patchMenu(store, { payload, _query }) {
      const config = _query && { params: { _query } };
      const { data } = await this._vm.api.patch(`/menu/${payload.id}`, zip(payload), config);
      return data;
    },
    async lockMenu({ commit, state, dispatch }, menu) {
      const payload = { id: menu.id, meta: { locked_by_user: menu.meta.locked_by_user } };
      const data = await dispatch('patchMenu', {
        payload,
        _query: '{id,date,meta}',
      }).catch((err) => {
        const isServerError =
          err && err.response && err.response.data && err.response.data.code >= 500;
        if (!isServerError) throw err;
        console.warn('request error locking menu, retrying', err);
        return dispatch('patchMenu', {
          payload,
          _query: '{id,date,meta}',
        }).catch((_err) => {
          console.error(`Could not lock menu ${menu.id}`, _err);
          throw _err;
        });
      });

      const modified_menu = Object.assign(cloneDeep(state.menus[menu.id]), {
        date: data.date,
        meta: data.meta,
      });
      commit('setMenu', { id: menu.id, menu: modified_menu });

      return cloneDeep(modified_menu);
    },
    async deleteMenu({ commit }, id) {
      const { data } = await this._vm.api.delete(`/menu/${id}`);
      commit('removeMenu', { id });
      return data;
    },

    async uploadFrictionlessMenu(store, payload) {
      const {
        fileObj: { name, content },
        bucket_name,
      } = payload;
      const fileBase64 = Buffer.from(JSON.stringify(content)).toString('base64');
      const filePayload = { file_name: name, file_base64: fileBase64, bucket_name };

      // Upload file to relevant S3 bucket
      await this._vm.api.post(`/file`, filePayload);
    },

    async uploadOnemarketMenu(store, payload) {
      let menus;

      const {
        location_group_id,
        fileObj: { name, content },
        bucket_name,
      } = payload;

      const filePayload = { file_name: name, file_base64: content, bucket_name };

      // upload file to relevant S3 bucket
      const { data: uploadData } = await this._vm.api.post(`/file`, filePayload);

      // persist menu import
      if (uploadData) {
        menus = await this._vm.api
          .post(`partner/jKpeYXLmyXU2jX2egv9jfPllGy2lMzuAXPPEJMM7C7P4Z37Agv/menu`, {
            location_group: location_group_id,
            s3_link: uploadData.url,
          })
          .then((r) => r.menus);
      }
      return menus;
    },

    async getMenuItems(store, query) {
      const response = await this._vm.api.get('/menu/items', { params: { query } });
      return response.data.items;
    },

    async fetchCompanyMenu({ commit, state, getters }, { id, nocache }) {
      if (state.companiesFetchedMap[id] && !nocache) {
        return cloneDeep({
          id,
          menus: getters.getCompanyMenus(id),
        });
      }
      const res = await this._vm.api.get(`/menu/company/${id}`).catch((err) => {
        const isServerError =
          err && err.response && err.response.data && err.response.data.code >= 500;
        if (!isServerError) throw err;
        console.warn('request error getting company menus, retrying', err);
        return this._vm.api.get(`/menu/company/${id}`).catch((_err) => {
          commit('setCompanyFetched', { id, value: false });
          throw _err;
        });
      });
      commit('setMenus', res.data.menus);
      commit('setCompanyFetched', { id, value: true });
      return cloneDeep({
        id,
        menus: getters.getCompanyMenus(id),
      });
    },
    async fetchSectorMenu({ commit, state, getters }, { id, nocache }) {
      if (state.sectorsFetchedMap[id] && !nocache) {
        return cloneDeep({
          id,
          menus: getters.getSectorMenus(id),
        });
      }
      const res = await this._vm.api.get(`/menu/sector/${id}`).catch((err) => {
        commit('setSectorFetched', { id, value: false });
        throw err;
      });
      commit('setMenus', res.data.menus);
      commit('setSectorFetched', { id, value: true });
      const company_ids = [...new Set(res.data.menus.map((menu) => menu.company))];
      company_ids.forEach((company_id) => {
        commit('setCompanyFetched', { id: company_id, value: true });
      });
      return cloneDeep({
        id,
        menus: getters.getSectorMenus(id),
      });
    },
    async fetchCompanyGlobalModGroups({ commit }, { id }) {
      const res = await this._vm.api.get(`/menu/modifier/group/company/${id}`);
      commit('setCompanyGlobalModGroups', res.data.modifier_groups);
      return cloneDeep({ modifier_groups: res.data.modifier_groups });
    },
    async fetchGlobalModGroup({ commit }, { id }) {
      const response = await this._vm.api
        .get(`/menu/modifier/group/${id}`)
        .catch(() => this._vm.api.get(`/menu/modifier/group/${id}`));
      commit('setGlobalModGroup', { id, value: response.data });
      return response.data;
    },
    async deleteGlobalModGroup(state, id) {
      const { data } = await this._vm.api.delete(`/menu/modifier/group/${id}`);
      return data;
    },
    async postGlobalModGroup(state, globalModGroup) {
      const { data } = await this._vm.api.post('/menu/modifier/group', globalModGroup);
      return data;
    },
    async putGlobalModGroup(state, globalModGroup) {
      const { data } = await this._vm.api.put(
        `/menu/modifier/group/${globalModGroup.id}`,
        globalModGroup,
      );
      return data;
    },
    async saveFrictionless({ dispatch }, payload) {
      const brand = { ...payload };
      await dispatch('sites/patchLocationBrand', { ...brand }, { root: true });
      await dispatch('sites/fetchBrand', { id: brand.id }, { root: true, nocache: true });
    },
  },
  getters: {
    getMenusArray: (state) => {
      return Object.values(state.menus);
    },
    getSectorMenus: (state, getters) => (sector_id) => {
      return getters.getMenusArray.filter((menu) => menu && menu.sector === sector_id);
    },
    getMenusByCompany: (state, getters) => {
      const companyMenus = {};
      getters.getMenusArray.forEach((m) => {
        if (!m || !m.company) return;
        if (!companyMenus[m.company]) {
          companyMenus[m.company] = [];
        }
        companyMenus[m.company].push(m);
      });
      return companyMenus;
    },
    getCompanyMenus: (state, getters) => (company_id) => {
      return getters.getMenusByCompany[company_id] || [];
    },
    getCompanyMenuRecent: (state, getters) => (company_id, local) => {
      return getters.getCompanyMenus(company_id).reduce((recent, menu) => {
        if (!local && menu.parent_id) return recent;
        if (local && !menu.parent_id) return recent;
        if (local && menu.location_brand !== local) return recent;
        if (getters.isMenuLocked(recent.id)) return recent;
        if (getters.isMenuLocked(menu.id)) return menu;

        if (!recent.date) return menu;
        if (!menu.date) return recent;
        const recentMenuActivity = DateTime.fromISO(recent.date.published || recent.date.modified);
        const currentMenuActivity = DateTime.fromISO(menu.date.published || menu.date.modified);
        return currentMenuActivity > recentMenuActivity ? menu : recent;
      }, {});
    },
    isMenuLocked: (state) => (menu_id, user_id) => {
      const menu = state.menus[menu_id];
      if (!menu) return false;

      const locked_by_user = menu.meta && menu.meta.locked_by_user;
      if (!locked_by_user) return false;

      const lockExpired =
        Math.abs(
          DateTime.fromISO(menu.date.modified)
            .diffNow('minutes')
            .toObject().minutes,
        ) > state.sessionLength;

      if (lockExpired) return false;

      return locked_by_user !== user_id;
    },
    isMenuLockExpired: (state) => (id) => {
      const menu = state.menus[id];
      if (!menu) return true;
      if (!menu.date || !menu.date.modified) return true;

      const expiry = state.sessionLength;
      return DateTime.utc() >= DateTime.fromISO(menu.date.modified).plus({ minutes: expiry });
    },
    isSomeMenuLocked: (state, getters) => (local, userId, stationId, companyId) => {
      // If any menu sets are locked, they should all be locked
      try {
        return getters.getMenusArray.some((m) => {
          if (!m) return false;
          if (companyId && companyId !== m.company) return false;
          if (local && !m.parent_id) return false;
          if (!local && m.parent_id) return false;
          if (stationId && stationId !== m.location_brand) return false;
          if (!stationId && m.location_brand) return false;

          return getters.isMenuLocked(m.id, userId);
        });
      } catch (err) {
        console.error(err);
        return true;
      }
    },
  },
};
