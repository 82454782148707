import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import plugins
import './plugins/vuetify';
// import './plugins/toast';
import './plugins/confirm';
import './plugins/vue2-filters';
import './plugins/analytics';
import './plugins/vue-observe-visibility';
import './plugins/vue-croppa';
import './plugins/global-components';
import './plugins/json-viewer';

// admin panel
import adminpanel from './plugins/adminpanel';

import '@/assets/design.styleguide.css';
import '@/assets/typography.css';
import '@/assets/vuetify.custom.css';

Vue.config.productionTip = false;
Vue.use(adminpanel);

(async () => {
  await store.dispatch('adminPanel/init');
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
