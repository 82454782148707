<template>
  <v-layout wrap justify-center align-center v-if="!show">
    <v-flex xs12>
      <v-img :src="require('@/assets/construction.svg')" height="40vh" contain></v-img>
    </v-flex>
    <v-flex shrink>
      <span class="Body-1-Black-High-Emphasis-Center">{{ message }}</span>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['message', 'show'],
};
</script>

<style></style>
