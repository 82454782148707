import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      staticClass: "confirm-dialog",
      attrs: {
        value: "true",
        "max-width": _vm.width,
        persistent: _vm.persistent,
        "app-data": "true"
      },
      on: {
        input: _vm.change,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.choose(false)
        }
      }
    },
    [
      _c(
        VCard,
        { staticClass: "confirm-dialog", attrs: { tile: "" } },
        [
          _c(VCardText, { staticStyle: { padding: "24px" } }, [
            _c("div", { staticClass: "H4-Secondary-Center" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("div", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
              _vm._v(_vm._s(_vm.message))
            ])
          ]),
          _c(
            VCardActions,
            { staticStyle: { padding: "14px 24px" } },
            [
              _c(VSpacer),
              Boolean(_vm.buttonFalseText)
                ? _c(
                    VBtn,
                    {
                      attrs: { color: _vm.buttonFalseColor, flat: "" },
                      on: {
                        click: function($event) {
                          return _vm.choose(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.buttonFalseText))]
                  )
                : _vm._e(),
              Boolean(_vm.buttonTrueText)
                ? _c(
                    VBtn,
                    {
                      attrs: { color: _vm.buttonTrueColor, flat: "" },
                      on: {
                        click: function($event) {
                          return _vm.choose(true)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.buttonTrueText))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }