import { get } from 'lodash';

export function createImportError(sheetName, row, message, name = null) {
  return {
    sheetName,
    row,
    message,
    name,
  };
}

export function getDuplicateErrorMessage(entryType, field, duplicate) {
  if (duplicate.duplicateSheet && duplicate.duplicateRowNum) {
    return `"${field}" is a duplicate of row ${duplicate.duplicateRowNum} on sheet "${duplicate.duplicateSheet}".`;
  }

  if (duplicate.duplicateRowNum) {
    return `"${field}" is a duplicate of row ${duplicate.duplicateRowNum}.`;
  }

  return `"${field}" is a duplicate of an existing ${entryType}.`;
}

export function findDuplicatesByField(array, field) {
  return array.reduce((duplicates, elem, index) => {
    const elemVal = field ? get(elem, field) : elem;
    if (elemVal === undefined) return duplicates; // ignore if value is undefined

    const duplicateIndex = array.findIndex((duplicate, dIndex) => {
      const duplicateVal = field ? get(duplicate, field) : duplicate;
      return !!duplicateVal && dIndex !== index && duplicateVal === elemVal;
    });

    if (duplicateIndex >= 0 && duplicateIndex !== index) {
      if (typeof elem === 'object') {
        elem.duplicateRowNum = array[duplicateIndex].excelSheetRow;
        elem.duplicateSheet = array[duplicateIndex].excelSheetName;
      }
      duplicates.push(elem);
    }

    return duplicates;
  }, []);
}

export function tryGetRowValue(header, headers, row) {
  const headerIndex = headers.values.indexOf(header);

  if (headerIndex < 0) {
    throw new Error('index out of bounds');
  } else if (headerIndex >= row.values.length) {
    // last cell(s) in row are empty
    return undefined;
  }

  let rowValue = row.values[headerIndex];
  if (typeof rowValue === 'string') {
    rowValue = rowValue.trim();
    // ignore empty string values
    if (rowValue.length === 0) return undefined;
  } else if (
    header === 'Price' &&
    (rowValue === '' || rowValue === undefined || rowValue === null)
  ) {
    rowValue = 0;
  }
  return rowValue;
}

export function parseRow(fields, headers, sheetName, row) {
  const errors = [];
  const parsedData = {};

  const typeExamples = {
    boolean: '(TRUE/FALSE)',
  };

  Object.keys(fields).forEach((key) => {
    const value = fields[key];

    try {
      parsedData[key] = tryGetRowValue(key, headers, row);
    } catch (e) {
      errors.push(
        createImportError(sheetName, row._number, `Error trying to read "${key}" value.`),
      );
    }

    if (value.required && parsedData[key] === undefined) {
      errors.push(
        createImportError(
          sheetName,
          row._number,
          `"${key}" is a required field. Please enter a ${value.type} value. ${typeExamples[
            value.type
          ] || ''}`,
        ),
      );
    } else if (parsedData[key] !== undefined) {
      const typeError = createImportError(
        sheetName,
        row._number,
        `"${key}" must be a ${value.type}. ${typeExamples[value.type] || ''}`,
      );

      if (value.type === 'number') {
        // eslint-disable-next-line valid-typeof
        if (value.type !== typeof parsedData[key]) {
          parsedData[key] = Number(parsedData[key]);
        }

        if (Number.isNaN(parsedData[key])) {
          errors.push(typeError);
        } else if (value.integerOnly && !Number.isInteger(parsedData[key])) {
          errors.push(
            createImportError(
              sheetName,
              row._number,
              `"${key}" must be a whole number (no decimals).`,
            ),
          );
        }
        // eslint-disable-next-line valid-typeof
      } else if (value.type !== typeof parsedData[key]) {
        errors.push(typeError);
      }
    }
  });

  return { errors, parsedData };
}

export function validateImportHeaders(requiredHeaders, headers, sheetName) {
  const headerErrors = requiredHeaders.reduce((errors, requiredHeader) => {
    if (!headers.values.includes(requiredHeader)) {
      errors.push(
        createImportError(
          sheetName,
          1, // headers are row 1
          `Missing column: "${requiredHeader}".`,
        ),
      );
    }
    return errors;
  }, []);

  headerErrors.push(
    ...headers.values.reduce((errors, header) => {
      if (!requiredHeaders.includes(header)) {
        errors.push(
          createImportError(
            sheetName,
            1, // headers are row 1
            `Unknown column: "${header}".`,
          ),
        );
      }
      return errors;
    }, []),
  );

  return headerErrors;
}
