<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex md12 align-center v-if="user" justify-space-between style="display: flex;">
        <div class="H4-Primary-Left">Hey There {{ user.name.first }}.</div>
        <div class="d-flex justify-end align-center">
          <img
            v-if="selectedSite.style && selectedSite.style.logo"
            class="site_logo"
            :src="selectedSite.style.logo"
            :alt="selectedSite.label.en + ' logo'"
          />
          <v-select
            :items="sitesAvailable"
            :value="selectedSite.id"
            @change="selectSite"
            style="max-width: 333px;"
            v-if="sitesAvailable.length > 1"
          />
        </div>
      </v-flex>
      <loading-wrapper :loading="loading" mt="20vh" :size="100">
        <v-flex xs12>
          <v-card>
            <v-card-title class="H6-Selected-On-Surface-High-Emphasis-Left pl-3">
              {{ selectedSite.parentMultigroup.name }} at {{ selectedSite.label.en }}
            </v-card-title>
            <v-card-text class="Body-1-Black-High-Emphasis-Left pl-3">
              <p>Use the Admin Panel to help you manage your mobile ordering application!</p>
              <p>Start by clicking on the side bar and navigate through the available features.</p>
              <p>
                Need Help? Visit the CDL Digital Support Center,
                <a href="https://www.cdlsupport.io" style="text-decoration: none;">CDLsupport.io</a>
                to find resource materials or submit a ticket to Digital Care.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title class="H6-Selected-On-Surface-High-Emphasis-Left pl-3">
              Station List
            </v-card-title>
            <v-flex xs7 ml-2>
              <v-text-field
                :label="searchLabel"
                prepend-inner-icon="mdi-magnify"
                clearable
                v-model="searchField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="availableBrands"
                :search="searchField"
                hide-actions
              >
                <v-progress-linear
                  v-slot:progress
                  color="blue"
                  indeterminate
                  height="7"
                ></v-progress-linear>
                <template v-slot:items="props">
                  <tr>
                    <td class="clickable" @click="goToBrand(props.item)">{{ props.item.name }}</td>
                    <td class="clickable" @click="goToBrand(props.item)">
                      {{ props.item.location_description }}
                    </td>
                    <td>
                      <div>
                        Pickup:
                        <span
                          class="Body-1-Black-High-Emphasis-Left"
                          :style="
                            showBoldedHours(props.item.pickupDeliveryHours.pickupTimes)
                              ? 'font-weight: bold'
                              : ''
                          "
                        >
                          <a
                            @click="
                              goToBrand({
                                id: props.item.id,
                                name: props.item.name,
                                tab: 'pickup',
                              })
                            "
                          >
                            {{ props.item.pickupDeliveryHours.pickupTimes }}
                          </a>
                        </span>
                      </div>
                      <div class="clickable" v-if="props.item.pickupDeliveryHours.deliveryTimes">
                        Delivery:
                        <span
                          class="Body-1-Black-High-Emphasis-Left"
                          :style="
                            showBoldedHours(props.item.pickupDeliveryHours.deliveryTimes)
                              ? 'font-weight: bold'
                              : ''
                          "
                        >
                          <a
                            @click="
                              goToBrand({
                                id: props.item.id,
                                name: props.item.name,
                                tab: 'delivery',
                              })
                            "
                          >
                            {{ props.item.pickupDeliveryHours.deliveryTimes }}
                          </a>
                        </span>
                      </div>
                      <div
                        v-if="props.item.brand_status && props.item.brand_status.is_closed"
                        class="Temporarily-closed"
                      >
                        <v-img class="svg-logo" :src="require('@/assets/store-front.svg')"></v-img>
                        {{ props.item.reopen_message }}
                      </div>
                    </td>
                    <td style="padding: 0 !important;" v-if="showMenuColumn">
                      <v-btn
                        class="ma-0"
                        :disabled="!props.item.brandMenusLink"
                        outline
                        color="primary"
                        @click="goToBrandMenus(props.item.id)"
                      >
                        {{ props.item.brandMenusLink ? 'VIEW MENU' : 'MENU UNAVAILABLE' }}
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>
      </loading-wrapper>
    </v-layout>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'vuex';
import * as BrandHoursHelpers from '@/views/sites/helpers';
import { CalendarTypes } from '@/constants';
import DateTime from 'luxon/src/datetime.js';

export default {
  data: () => ({
    selectedSite: {
      label: { en: '' },
      address: {
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
      id: '',
      name: '',
      locations: [],
      parentMultigroup: { id: '', name: '' },
    },
    defaultHeaders: [
      {
        text: 'Station Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Location',
        align: 'left',
        value: 'location_description',
      },
      {
        text: "Today's Hours",
        align: 'left',
        value: 'pickupDeliveryHours.pickupTimes',
      },
      {
        text: 'Menus',
        align: 'left',
        value: 'brandMenusLink',
      },
    ],
    loading: true,
    searchField: '',
    siteCompanies: [],
    siteBrands: [],
  }),
  computed: {
    ...mapState('adminPanel', ['user']),
    ...mapState('users', ['customPermissions']),
    ...mapState('sites', ['siteMap', 'active_site']),
    searchLabel() {
      return `Search Stations in ${this.selectedSite.parentMultigroup.name} - ${this.selectedSite.label.en}`;
    },
    availableBrands() {
      return this.siteBrands.map((brand) => {
        const company = this.siteCompanies.find((c) => c.id === brand.company);
        const { calendar = {} } = brand;
        const brandMenus =
          company &&
          Array.isArray(company.menus) &&
          company.menus.filter((m) => m.location_brand === brand.id && m.is && m.is.linked);
        const brandHasMenus = brandMenus && brandMenus.length;
        const brandMenusLink = brandHasMenus
          ? window.location.href.replace(
              /#\/.+/,
              `/#/menu/sector/${brand.sector}/company/${brand.company}/brand/${brand.id}`,
            )
          : '';
        const brandReopenTime = this.getReopenTime(brand, calendar.events);
        const pickupTimes = this.getOpenHoursForTheDay(
          brandReopenTime,
          brand,
          calendar,
          CalendarTypes.business,
        );
        const deliveryTimes = this.getOpenHoursForTheDay(
          brandReopenTime,
          brand,
          calendar,
          CalendarTypes.delivery,
        );

        const brandWithAdditionalData = {
          id: brand.id,
          name: (brand.label && brand.label.en) || brand.name || 'Unknown',
          location_description: brand.location_description,
          pickupDeliveryHours: {
            pickupTimes,
            deliveryTimes,
          },
          brandMenusLink,
        };
        if (brandReopenTime) {
          const todaysDate = DateTime.local();
          const isClosed = brandReopenTime >= todaysDate;
          const brandStatus = {
            is_closed: isClosed,
          };
          const reopenMessage = isClosed
            ? this.getReopenMessage(brandReopenTime, brand.timezone)
            : '';
          brandWithAdditionalData.reopen_message = reopenMessage;
          brandWithAdditionalData.brand_status = brandStatus;
        }
        return brandWithAdditionalData;
      });
    },
    showMenuColumn() {
      const user_permissions =
        (this.user && this.user.permissions && this.user.permissions.scopes) || [];
      return user_permissions.includes(this.customPermissions.menuAccess);
    },
    headers() {
      let headers = cloneDeep(this.defaultHeaders);
      if (!this.showMenuColumn) {
        headers = headers.filter((h) => h.text !== 'Menus');
      }
      return headers;
    },
    sitesAvailable() {
      return Object.values(this.siteMap || {}).map((site) => {
        if (site) {
          return {
            text: site.label.en,
            value: site.id,
          };
        }
        return null;
      });
    },
    firstSiteId() {
      const siteIds = Object.keys(this.siteMap);
      return siteIds[0];
    },
  },
  watch: {
    firstSiteId: {
      async handler(newValue) {
        if (!newValue || this.selectedSite.id) return;
        try {
          await this.loadSite(newValue);
        } catch (err) {
          console.error(err);
          this.$toast.error('Could not load stations list');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...BrandHoursHelpers,
    ...mapActions('sites', ['fetchAll', 'fetchSite']),
    ...mapActions('calendar', ['getCalendar']),
    ...mapActions('menus', ['fetchCompanyMenu']),
    async loadSite(id) {
      this.loading = true;
      if (!id) return;
      const site = this.siteMap[id];
      if (!site || !site.parentMultigroup || !site.parentMultigroup.id) return;
      await this.fetchSite({
        id,
        multigroupId: site.parentMultigroup.id,
        fetchWithPermissions: true,
      });
      this.selectedSite = {
        ...this.siteMap[id],
      };
      let siteCompanyIds = new Set();
      const siteBrands = [];
      const calendarPromises = [];
      this.selectedSite.locations.forEach((l) => {
        if (!l.brands) return;
        const location_brands = cloneDeep(l.brands);
        siteBrands.push(...location_brands);
        location_brands.forEach((brand) => {
          calendarPromises.push(
            this.getCalendar({ id: brand.id }).then((calendar) => {
              this.$set(brand, 'calendar', calendar);
            }),
          );
          if (!brand.company) return;
          siteCompanyIds.add(brand.company);
        });
      });
      this.siteBrands = siteBrands;
      siteCompanyIds = [...siteCompanyIds];
      this.siteCompanies = await Promise.all(
        siteCompanyIds.map((companyId) => this.fetchCompanyMenu({ id: companyId })),
      );
      await Promise.all(calendarPromises);
      this.loading = false;
    },
    async selectSite(id) {
      if (!id) return;
      await this.loadSite(id);
    },
    goToBrandMenus(brandId) {
      const brand = this.siteBrands.find((b) => b.id === brandId);
      this.$router.push({
        name: 'menu-local',
        params: {
          brand_id: brand.id,
          company_id: brand.company,
          sector_id: brand.sector,
        },
      });
    },
    goToBrand({ id, name, tab }) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: name,
        to: {
          name: `brand-overview-${tab || 'menus'}`,
          params: { brand_id: id, site_id: this.selectedSite.id },
        },
      });

      this.$router.push({
        name: `brand-overview-${tab || 'menus'}`,
        params: {
          app: this.selectedSite.parentMultigroup.id,
          site_id: this.selectedSite.id,
          brand_id: id,
        },
      });
    },
    showBoldedHours(hours) {
      return hours === 'Closed' || hours === 'N/A';
    },
  },
};
</script>

<style scoped>
div >>> .v-input__prepend-inner {
  padding: 1rem !important;
  color: #0d73d8;
}
div >>> .v-text-field__slot .v-label,
.v-text-field__slot input {
  font-size: 18px;
}
div >>> .site_logo {
  max-height: 82px;
  max-width: 216px;
  margin-right: 16px;
  margin-bottom: 16px;
}
div >>> table.v-datatable tbody td,
div >>> .v-datatable thead tr th {
  font-size: 18.1px;
}
.Temporarily-closed {
  font-family: Avenir;
  font-size: 13.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  max-height: 200px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.svg-logo {
  width: 24px;
  height: 24px;
  display: inline-block;
  float: left;
  margin-right: 8px;
  max-width: 24px;
}
</style>
