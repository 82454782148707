<template>
  <v-container grid-list-xl>
    <v-layout v-if="isSiteOperator || isImUser" row wrap>
      <site-operator />
    </v-layout>
    <v-layout v-else row wrap>
      <v-flex md12 v-if="user">
        <div class="H3-Primary-Left">Hi {{ user.name.first }} {{ user.name.last }}</div>
      </v-flex>
      <v-flex md12>
        <v-card>
          <v-card-title primary-title>
            <div class="H4-Primary-Left">Welcome to the CDL Admin Panel</div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex md12>
        <v-img :src="require('./home.svg')"></v-img>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import siteOperator from './siteOperator';

export default {
  data: () => ({}),
  components: {
    siteOperator,
  },
  computed: {
    ...mapState('adminPanel', ['user']),
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser']),
  },
};
</script>

<style></style>
