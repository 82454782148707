import Vue from 'vue';
// eslint-disable-next-line import/no-named-default
import { default as Vuetify, VTextField, VSnackbar, VIcon } from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';
import currency from 'v-currency-field';
// CSS IMPORTS
import '@mdi/font/css/materialdesignicons.css';
// import 'vuetify/dist/vuetify.min.css';
import '../stylus/main.styl';

import vClickOutside from 'v-click-outside';
import VuetifyToast from 'vuetify-toast-snackbar';
import Fragment from 'vue-fragment';
import VueApexCharts from 'vue-apexcharts';

Vue.component('v-text-field', VTextField); // needed for v-currency-field
Vue.component('v-snackbar', VSnackbar); // needed for v-currency-field
Vue.component('v-icon', VIcon); // needed for v-currency-field
Vue.component('apexchart', VueApexCharts); // needed for analytics dashboard

Vue.use(Fragment.Plugin);
Vue.use(vClickOutside);

const theme = {
  primary: {
    base: '#0D73D8',
    lighten5: '#E2F2FE',
    lighten4: '#BADDFD',
    lighten3: '#8DC9FD',
    lighten2: '#5DB3FB',
    lighten1: '#38A3FB',
    darken1: '#0793FA',
    darken2: '#0D85EB',
    darken3: '#0D73D8',
    darken4: '#0C62C6',
    darken5: '#0A43A7',
  },
  secondary: {
    base: '#090C9B',
    lighten5: '#ECE5F7',
    lighten4: '#CEC0EA',
    lighten3: '#AE97DD',
    lighten2: '#8D6CD0',
    lighten1: '#744CC7',
    darken1: '#592DBC',
    darken2: '#4F28B7',
    darken3: '#4020AE',
    darken4: '#301AA7',
    darken5: '#090C9B',
  },
  light: '#FFFFFF',
  dark: '#F5F5F5',
  turqouise: '#008078',
  'dark-grey': '#656465',
  'grey-white': '#FAFAFA',
  'light-grey': '#eeeeee',
  'dark-blue': '#080C9A',
};

Vue.use(currency);
Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
  iconfont: 'mdi',
  theme,
  directives: {
    Ripple,
  },
});
Vue.use(VuetifyToast, {
  x: 'middle', // default
  y: 'top', // default
  color: 'primary', // default
  icon: 'mdi-information-outline',
  classes: ['ap-toast'],
  timeout: 4000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: true, // default
  // showClose: true, // default
  // shorts: {
  //   custom: {
  //     color: 'purple',
  //   },
  // },
  property: '$toast', // default
});
