<template>
  <div>
    <div :class="dropDownItems && 'title-brand clickable'" @click="toggleSearch">
      <div class="H3-Primary-Left">{{ active_title }}</div>
      <div class="brand-description">{{ this.description }}</div>
      <v-icon v-if="dropDownItems" class="chevron-down">mdi-chevron-down</v-icon>
    </div>
    <v-flex xs6 class="search" v-if="search && dropDownItems">
      <v-autocomplete
        :items="dropDownItems"
        :value="label !== 'Search Sites' ? activeItem : activeSite"
        item-text="name"
        item-value="id"
        return-object
        hide-no-data
        :label="label"
        prepend-inner-icon="mdi-magnify"
        @change="onChange"
      ></v-autocomplete>
    </v-flex>
    <v-flex xs6 class="search" v-if="search && secondaryItemsList && secondaryItemsList.length">
      <v-autocomplete
        :items="secondaryItemsList"
        :value="secondaryActiveItem"
        item-text="name"
        item-value="id"
        return-object
        hide-no-data
        :label="secondaryItemLabel"
        prepend-inner-icon="mdi-magnify"
        @change="$emit('secondaryItemChanged', $event)"
      ></v-autocomplete>
    </v-flex>
    <div>
      <div v-if="address" class="timezone-address">
        <v-img class="svg-logo" :src="require('@/assets/location.svg')"></v-img>
        <p>
          <strong>Address:</strong>
          {{ address }}
        </p>
      </div>
      <div v-if="timezone" class="timezone-address">
        <v-img class="svg-logo" :src="require('@/assets/clock.svg')"></v-img>
        <p>
          <strong>Timezone:</strong>
          {{ timezone }}
        </p>
      </div>
    </div>
    <div v-if="active_sub_title">{{ active_sub_title }}</div>
    <v-breadcrumbs
      class="breadcrumbs"
      :items="links"
      divider=">"
      large
      v-if="links.length > 1"
    ></v-breadcrumbs>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    search: false,
    site: '',
  }),
  props: {
    address: {
      type: String,
      required: false,
    },
    timezone: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      default: null,
    },
    goToSiteRoute: {
      type: String,
      default: 'site-info',
      required: false,
    },
    dropDownItems: {
      type: Array,
      required: false,
    },
    secondaryItemsList: {
      type: Array,
      required: false,
    },
    activeItem: {
      type: Object,
      required: false,
    },
    secondaryActiveItem: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    secondaryItemLabel: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState('adminPanel', ['breadcrumbs', 'active_title', 'active_sub_title']),
    links() {
      const links = [];
      for (const index of Object.keys(this.breadcrumbs).sort()) {
        links.push(this.breadcrumbs[index]);
      }
      return links;
    },
    activeSite() {
      return this.dropDownItems.find((i) => i.id === this.$route.params.site_id);
    },
  },
  methods: {
    toggleSearch() {
      this.search = !this.search;
    },
    async goToSite(site) {
      const route = this.goToSiteRoute;
      if (this.$route.params.site_id !== site.id) {
        const multiGroupId = this.$route.params.app;
        this.$store.commit('adminPanel/setLoading', true);
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: site.name,
          to: { name: route, params: { site_id: site.id, app: multiGroupId } },
        });
        this.$router.push({
          name: route,
          params: {
            app: multiGroupId,
            site_id: site.id,
          },
        });
        this.$store.dispatch('sites/fetchSite', {
          id: site.id,
        });
        this.$store.commit('adminPanel/setLoading', false);
      }
    },
    onChange(item) {
      if (this.label === 'Search Sites') {
        this.goToSite(item);
      } else {
        this.$emit('primaryItemChanged', item);
      }
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding-left: 0;
}

.layout .flex.search {
  padding-left: 0;
}

.timezone-address {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.timezone-address p {
  margin: 0;
  width: 100%;
}

.title-brand {
  display: flex;
}

.chevron-down {
  color: #0d73d8 !important;
  margin-left: 8px;
  font-size: 300%;
}

.svg-logo {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 12px;
}
.brand-description {
  font-family: Avenir;
  font-size: 1.25em;
  font-stretch: normal;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0.5em;
}
</style>
