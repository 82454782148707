import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _vm.isSiteOperator || _vm.isImUser
        ? _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [_c("site-operator")],
            1
          )
        : _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _vm.user
                ? _c(VFlex, { attrs: { md12: "" } }, [
                    _c("div", { staticClass: "H3-Primary-Left" }, [
                      _vm._v(
                        "Hi " +
                          _vm._s(_vm.user.name.first) +
                          " " +
                          _vm._s(_vm.user.name.last)
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                VFlex,
                { attrs: { md12: "" } },
                [
                  _c(
                    VCard,
                    [
                      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
                        _c("div", { staticClass: "H4-Primary-Left" }, [
                          _vm._v("Welcome to the CDL Admin Panel")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { md12: "" } },
                [_c(VImg, { attrs: { src: require("./home.svg") } })],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }