import { SplitFactory } from '@splitsoftware/splitio';

export default {
  namespaced: true,
  state: {
    featureFlags: {},
  },
  getters: {
    getFeatureFlags(state) {
      return state.featureFlags;
    },
    isFeatureOn: (state) => (key) => {
      return state.featureFlags[key];
    },
  },
  mutations: {
    setFeatureFlags(state, flags) {
      state.featureFlags = flags;
    },
  },
  actions: {
    async fetchSplits({ commit }, { email = undefined } = {}) {
      const factory = SplitFactory({
        core: {
          authorizationKey: process.env.VUE_APP_SPLIT_KEY,
          key: email,
        },
      });
      const client = factory.client();
      const manager = factory.manager();
      try {
        await client.ready();
        await manager.ready();

        const split_names = manager.names();
        const treatments = client.getTreatments(split_names);
        const flags = {};
        Object.keys(treatments).forEach((key) => {
          flags[key] = treatments[key] === 'on';
        });
        commit('setFeatureFlags', flags);
        client.destroy();
      } catch (error) {
        console.error(error);
        client.destroy();
      }
    },
  },
};
