<template>
  <v-dialog
    @input="change"
    value="true"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="choose(false)"
    class="confirm-dialog"
    app-data="true"
  >
    <v-card tile class="confirm-dialog">
      <v-card-text style="padding: 24px;">
        <div class="H4-Secondary-Center">{{ title }}</div>
        <div class="Body-1-Black-High-Emphasis-Left">{{ message }}</div>
      </v-card-text>
      <v-card-actions style="padding: 14px 24px;">
        <v-spacer />
        <v-btn
          v-if="Boolean(buttonFalseText)"
          :color="buttonFalseColor"
          flat
          @click="choose(false)"
          >{{ buttonFalseText }}</v-btn
        >
        <v-btn v-if="Boolean(buttonTrueText)" :color="buttonTrueColor" flat @click="choose(true)">{{
          buttonTrueText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VCard, VCardActions, VCardText, VDialog, VSpacer, VBtn } from 'vuetify/lib';

export default {
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VSpacer,
    VBtn,
  },
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes',
    },
    buttonFalseText: {
      type: String,
      default: 'No',
    },
    buttonTrueColor: {
      type: String,
      default: 'primary',
    },
    buttonFalseColor: {
      type: String,
      default: 'grey',
    },
    color: {
      type: String,
      default: 'warning',
    },
    icon: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      required: true,
    },
    persistent: Boolean,
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 350,
    },
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    choose(value) {
      this.$emit('result', value);
      this.value = value;
      this.$destroy();
    },

    change() {
      this.$destroy();
    },
  },
};
</script>

<style scoped>
.confirm-dialog {
  border-radius: 10px;
}
</style>
