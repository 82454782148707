import Vue from 'vue';
import Confirm from '@/components/confirm';

const options = {
  buttonTrueText: 'Accept',
  buttonFalseText: 'Discard',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 700,
  property: '$confirm',
};

const property = (options && options.property) || '$confirm';
function createDialogCmp(op) {
  return new Promise((resolve) => {
    const cmp = new Vue(
      Object.assign({}, Confirm, {
        propsData: Object.assign({}, Vue.prototype.$confirm.options, op),
        destroyed: () => {
          document.body.removeChild(cmp.$el);
          resolve(cmp.value);
        },
      }),
    );
    document.body.appendChild(cmp.$mount().$el);
  });
}

function show(op) {
  return createDialogCmp(op);
}

Vue.prototype[property] = show;
Vue.prototype[property].options = options || {};
