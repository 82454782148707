import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFooter,
    { attrs: { fixed: "", height: "60" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", reverse: "" } },
        [
          _c(VFlex, { attrs: { xs1: "" } }),
          _c(
            VFlex,
            { attrs: { shrink: "", "offset-xs1": "" } },
            [
              _c(
                VBtn,
                {
                  attrs: { flat: "", color: "blue" },
                  on: { click: _vm.cancelAction }
                },
                [_vm._v(_vm._s(_vm.cancelLabel))]
              ),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", loading: _vm.savingLoading },
                  on: { click: _vm.saveActionLoad }
                },
                [
                  _vm.showCreateIcon
                    ? _c(VIcon, { staticClass: "mr-1" }, [
                        _vm._v("mdi-content-save")
                      ])
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.saveLabel))
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }