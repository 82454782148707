import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "bell-button",
      attrs: { flat: "" },
      on: {
        click: function($event) {
          return _vm.toggleNotificationPanel(true)
        }
      }
    },
    [
      _c(VIcon, [_vm._v("mdi-bell-outline")]),
      _c(
        "div",
        [
          _c(
            VFlex,
            { class: _vm.unreadNotificationsExist ? "new-notification" : "" },
            [
              _vm.unreadNotifications.length
                ? _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.unreadNotifications.length) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }