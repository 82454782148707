<template>
  <v-app id="operator" :class="$vuetify.breakpoint.smAndDown && 'overflow-x-hidden'">
    <navigation
      v-if="!requireAuth"
      :mobileDrawer="mobileDrawer"
      @drawerStatus="mobileDrawer = $event"
    />
    <toolbar v-if="!requireAuth" @mobileMenuToggle="mobileDrawer = !mobileDrawer" />
    <notifications v-if="!requireAuth" />
    <v-content :class="contentclass">
      <div style="margin-top: 12px;" v-show="loading">
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12>
              <view-title />
            </v-flex>
            <v-layout column justify-center fill-height align-center style="height: 45vh;">
              <v-progress-circular
                :size="90"
                :width="9"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-layout>
          </v-layout>
        </v-container>
      </div>
      <router-view v-show="!loading" />
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import navigation from '@/components/navigation';
import toolbar from '@/components/toolbar';
import notifications from '@/components/notifications';
import AnalyticsHelperModule from '@/helpers/analytics-helper-module';
import { baseURL } from '@/plugins/axios';
import { heap } from '@/helpers/heap';

export default {
  name: 'adminpanel',
  components: {
    navigation,
    toolbar,
    notifications,
  },
  data: () => ({
    mobileDrawer: false,
  }),
  computed: {
    ...mapState('adminPanel', ['user']),
    loading() {
      return this.$store.state.adminPanel.loading;
    },
    requireAuth() {
      return this.$store.getters['adminPanel/requireAuth'];
    },
    contentclass() {
      const css = { admin_content: false };
      if (!this.requireAuth && this.$vuetify.breakpoint.mdAndUp) {
        css.admin_content = true;
      }
      return css;
    },
  },
  methods: {
    ...mapMutations('adminPanel', ['setStage']),
    ...mapActions('splitio', ['fetchSplits']),
    ...AnalyticsHelperModule,
    parseUrlForStage() {
      return baseURL.split('/').pop();
    },
  },
  mounted() {
    this.setStage(process.env.VUE_APP_STAGE);
    this.fetchSplits({ email: this.user?.email });
    heap().init();
  },
};
</script>

<style scoped>
.admin_content {
  padding-left: 80px !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
</style>
<style>
html * {
  font-family: 'Avenir', 'Poppins', 'sans-serif' !important;
}

table.v-table thead th {
  font-weight: bold !important;
}

i.mdi-magnify {
  color: #0d73d8 !important;
}
</style>
