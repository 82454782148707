import { get, set } from 'lodash';
// eslint-disable-next-line import/prefer-default-export
export const syncModGroupChanges = (from, to) => {
  const propsToSync = [
    'min',
    'max',
    'is.hidden',
    'is.disabled',
    'is.out_of_stock',
    'meta.sort_number',
    'meta.menu_sort_number',
    'meta.plu',
    'price.amount',
    'nutrition.calories.amount',
  ];
  to.items = to.items.filter((i) => from.items.find((_i) => _i.id === i.id));
  propsToSync.forEach((path) => {
    const modifiedValue = get(from, path);
    if (modifiedValue !== undefined) {
      set(to, path, modifiedValue);
    }
  });
  const isModGroupHidden = Boolean(to.is.hidden);
  const isModGroupOutOfStock = Boolean(to.is.out_of_stock);
  const isModGroupDisabled = Boolean(to.is.disabled);
  let allModifiersHidden = true;
  let allModifiersOutOfStock = true;
  let allModifiersDisabled = true;
  from.items.forEach((modifiedModifier) => {
    const originalModifier = to.items.find((i) => i.id === modifiedModifier.id);
    if (!originalModifier) {
      to.items.push(modifiedModifier);
    }
    if (originalModifier) {
      propsToSync.forEach((path) => {
        const modifiedValue = get(modifiedModifier, path);
        if (modifiedValue !== undefined) {
          set(originalModifier, path, modifiedValue);
        }
      });
    }
    const modifier = originalModifier || modifiedModifier;
    allModifiersHidden = Boolean(allModifiersHidden && modifier.is && modifier.is.hidden);
    allModifiersOutOfStock = Boolean(
      allModifiersOutOfStock && modifier.is && modifier.is.out_of_stock,
    );
    allModifiersDisabled = Boolean(allModifiersDisabled && modifier.is && modifier.is.disabled);
  });
  if (isModGroupHidden !== allModifiersHidden) {
    set(to, 'is.hidden', allModifiersHidden);
  }
  if (isModGroupOutOfStock !== allModifiersOutOfStock) {
    set(to, 'is.out_of_stock', allModifiersOutOfStock);
  }
  if (isModGroupDisabled !== allModifiersDisabled) {
    set(to, 'is.disabled', allModifiersDisabled);
  }
  return to;
};
