/* eslint-disable no-loop-func */
import cloneDeep from 'lodash/cloneDeep';

const DEFAULT_STATE = {
  promotions: [],
  promotionCompanies: [],
};

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations: {
    setPromotions(state, promotions) {
      state.promotions = promotions;
    },
    removePromotion(state, { id }) {
      const promoToDelete = state.promotions.find((p) => p.id === id);
      this._vm.$delete(state.promotions, state.promotions.indexOf(promoToDelete));
    },
    setPromoInPromotions(state, promoDetails) {
      const indexToUpdate = state.promotions.findIndex(
        (promo) => promo.id === promoDetails.updatedPromo.id,
      );
      this._vm.$set(state.promotions, indexToUpdate, {
        ...state.promotions[indexToUpdate],
        ...promoDetails.updatedPromo,
      });
    },
    insertPromoInPromotions(state, promoDetails) {
      if (promoDetails.insertAfterPromo === undefined) {
        state.promotions.push(promoDetails.newPromo);
        return;
      }
      state.promotions.splice(promoDetails.insertAfterPromo, 0, promoDetails.newPromo);
    },
    setPromotionCompanies(state, companies) {
      state.promotions = state.promotions.map((promotion) => ({
        ...promotion,
        company_name: companies.filter((company) => {
          return company.id === promotion.company;
        })[0].label.en,
      }));
      state.promotionCompanies = companies;
    },
  },
  getters: {
    getPromotion: (state) => (key) => {
      if (!key) return state.promotions || [];
      return state.promotions.filter((e) => e.data && e.data.key === key);
    },
    getPromotionCompanies: (state) => (key) => {
      if (!key) return state.promotionCompanies || [];
      return state.promotionCompanies.filter((e) => e.id && e.id === key);
    },
  },
  actions: {
    async fetchPromotion(store, id) {
      const { data } = await this._vm.api.get(`/promo/${id}?extended=true`);
      return cloneDeep(data);
    },
    async fetchPromotions({ commit }) {
      const { data } = await this._vm.api.get(`/promo`);
      const { promos } = data;
      commit('setPromotions', promos);
    },
    async deletePromotion({ commit }, id) {
      const { data } = await this._vm.api.delete(`/promo/${id}`);
      commit('removePromotion', { id });
      return data;
    },
    async putPromotion({ commit }, promoDetails) {
      const { promo } = promoDetails;
      const { data } = await this._vm.api.put(`/promo/${promo.id}`, promo);
      commit('setPromoInPromotions', { updatedPromo: data });
      return cloneDeep(data);
    },
    async postPromotion({ commit }, promoDetails) {
      const { promo, insertAfterPromo } = promoDetails;
      const { data } = await this._vm.api.post(`/promo`, promo);
      commit('insertPromoInPromotions', { insertAfterPromo, newPromo: data });
      return cloneDeep(data);
    },
    setPromotionCompanies({ commit }, companies) {
      commit('setPromotionCompanies', companies);
    },
  },
};
