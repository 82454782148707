import DateTime from 'luxon/src/datetime.js';
import { CalendarTypes } from '@/constants';

export const getBrandDateAndTimeToSave = (date, time, timezone) => {
  const DateTimeToSave = new Date(Date.parse(`${date} ${time}`));
  const brandTime = DateTime.local(
    DateTimeToSave.getFullYear(),
    DateTimeToSave.getMonth() + 1,
    DateTimeToSave.getDate(),
    DateTimeToSave.getHours(),
    DateTimeToSave.getMinutes(),
  );
  const newDate = brandTime.setZone(`${timezone}`, { keepLocalTime: true });
  const brandTimeUTC = newDate.setZone('UTC').toString();
  return brandTimeUTC;
};

export const formattedDate = (date) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const newDate = new Date(date);
  return new Date(
    newDate.getTime() + Math.abs(newDate.getTimezoneOffset() * 60000),
  ).toLocaleDateString('en-US', options);
};

export const getDateInBrandTimeZone = (date, brandTimeZone) => {
  const brandTime = DateTime.fromJSDate(date).setZone(brandTimeZone);
  return brandTime.toISO().substr(0, 10);
};

export const formatAMPM = (date) => {
  let hours = date.hour;
  let minutes = date.minute;
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes}${ampm}`;
  return strTime;
};

export const getTimeInBrandTimeZone = (time, brandTimeZone) => {
  const brandTime = DateTime.local(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    time.getHours(),
    time.getMinutes(),
  );
  const newDate = brandTime.setZone(`${brandTimeZone}`);
  return formatAMPM(newDate);
};

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const getReopenMessage = (reopenAt, timezone) => {
  if (!reopenAt) return null;
  const reopen = DateTime.fromISO(reopenAt).setZone(timezone);
  const time = reopen.toLocaleString(DateTime.TIME_SIMPLE);
  const dateString = reopen.toLocaleString(DateTime.DATE_MED);
  const currentTime = DateTime.local();
  const diff = reopen.diff(currentTime, 'days').toObject();
  const isOpeningSameDay = currentTime.toISO().substr(0, 10) === reopen.toISO().substr(0, 10);
  let date = 'Today';
  if (!isOpeningSameDay) {
    date = Math.abs(diff.days) <= 1 && reopen > currentTime ? 'Tomorrow' : `${dateString}`;
  }
  return `Brand closure under effect until: ${time} ${date}`;
};

const getEvents = (events, type) =>
  events && events.filter((e) => e.data && (!type || e.data.calendar === type));

export const getOpenHoursForTheDay = (reopenAt, brand, calendar, calendarType) => {
  // this one is used to figure out the open times for today
  try {
    const isDeliveryType = calendarType === CalendarTypes.delivery;
    const hours = isDeliveryType ? brand.deliveryHours : brand.hours;
    const { timezone } = brand;
    const calendarExists = !!(calendar && calendar.date && calendar.date.last_sync);
    const isCalendarTypeSupported =
      brand.is && (isDeliveryType ? brand.is.delivery_supported : brand.is.pickup_supported);
    if (!isCalendarTypeSupported) {
      return isDeliveryType ? '' : 'N/A';
    }
    if (!hours || !hours.length) {
      return calendarExists ? 'Closed' : 'N/A';
    }
    const currentDate = DateTime.local();
    const todaysWeekday = currentDate.weekday;
    const mappedHours = hours
      .map((h) => {
        if (!h.hours) return null;
        const startHours = h.hours.split('-')[0].split(':');
        const endHours = h.hours.split('-')[1].split(':');
        const startDate = DateTime.utc()
          .set({
            weekday: h.day.start,
            hour: startHours[0],
            minute: startHours[1],
            second: 0,
          })
          .setZone(timezone);
        const endDate = DateTime.utc()
          .set({
            weekday: !h.day.end && todaysWeekday === 6 ? 7 : h.day.end,
            hour: endHours[0],
            minute: endHours[1],
            second: 0,
          })
          .setZone(timezone);
        return {
          startDate,
          endDate,
        };
      })
      .filter((mp) => !!mp);
    let todaysHours = mappedHours.filter(
      (h) => h.startDate.weekday <= currentDate.weekday && h.endDate.weekday >= currentDate.weekday,
    );
    todaysHours = todaysHours.find(
      (h) => h.endDate >= currentDate || h.startDate.weekday === currentDate.weekday,
    );
    if (!todaysHours) return 'Closed';
    if (!reopenAt || reopenAt <= todaysHours.startDate || reopenAt <= currentDate) {
      const startHour = todaysHours.startDate.hour;
      const startMinute = todaysHours.startDate.minute;
      const endHour = todaysHours.endDate.hour;
      const endMinute = todaysHours.endDate.minute;
      return `${formatAMPM({ hour: startHour, minute: startMinute })} - ${formatAMPM({
        hour: endHour,
        minute: endMinute,
      })}`;
    }
    if (reopenAt > todaysHours.startDate && reopenAt < todaysHours.endDate) {
      const startHour = reopenAt.hour;
      const startMinute = reopenAt.minute;
      const endHour = todaysHours.endDate.hour;
      const endMinute = todaysHours.endDate.minute;
      return `${formatAMPM({ hour: startHour, minute: startMinute })} - ${formatAMPM({
        hour: endHour,
        minute: endMinute,
      })}`;
    }
    if (reopenAt >= todaysHours.endDate) {
      return 'Closed';
    }
  } catch (e) {
    console.error(e);
    return 'N/A';
  }
  return 'N/A';
};

export const getReopenTime = (brand, events = []) => {
  // the whole point of this function is to figure out when the brand will be open next
  const { timezone } = brand;
  const closures = getEvents(events, CalendarTypes.closure);
  const currentDate = DateTime.local().valueOf();
  let currentClosureEnd = closures.find(
    (c) => c.schedule.start <= currentDate && c.schedule.end >= currentDate,
  );
  if (currentClosureEnd) {
    const sortedClosures = closures.sort((c1, c2) => c1.schedule.end - c2.schedule.end);
    sortedClosures.forEach((c) => {
      if (
        c.schedule.start <= currentClosureEnd.schedule.end &&
        c.schedule.end >= currentClosureEnd.schedule.end
      ) {
        currentClosureEnd = c;
      }
    });
  }
  const closureEnd =
    currentClosureEnd && DateTime.fromMillis(currentClosureEnd.schedule.end).setZone(timezone);
  return closureEnd;
};

export const dayOfWeekAsInteger = (day) =>
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];

export const getStartAndEndTimes = (schedule) => {
  if (!schedule && !schedule.times && !schedule.duration) return {};
  if (!schedule.times) {
    return {
      start: '00:00',
      end: '00:00',
      isAllDay: true,
      isCloseAllDAy: false,
    };
  }
  const today = DateTime.local();
  const startTime = schedule.times[0];
  const startTimes = startTime.split(':');

  let startDate;

  if (startTimes.length === 1) {
    startDate = today.set({ hour: startTimes[0] });
  } else {
    startDate = today.set({ hour: startTimes[0], minute: startTimes[1] });
  }

  const endDate = startDate.plus({ hours: schedule.duration });

  return {
    start: startDate.toFormat('HH:mm'),
    end: endDate.toFormat('HH:mm'),
    isAllDay: false,
    isCloseAllDAy: false,
  };
};
