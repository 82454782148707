import cdlid from '@compassdigital/id';
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { UserRoles, CustomPermissions } from '@/constants';

export default {
  namespaced: true,
  state: {
    active_user: null,
    usersMap: {},
    usersMapNames: (localStorage.usersMapNames && JSON.parse(localStorage.usersMapNames)) || {},
    customPermissions: CustomPermissions,
  },
  mutations: {
    setUser(state, user) {
      state.active_user = user;
    },
    cacheUser(state, { id, user }) {
      // compute role
      if (user.permissions && user.permissions.scopes) {
        const role = user.permissions.scopes
          .map((e) => e.split(':')[0])
          .find((e) => e.includes('_role'));
        user.role = role || '';

        const superAdmin = user.permissions.scopes.find((e) => e === '*:*:*');
        if (superAdmin) {
          user.role = `${UserRoles.admin}_role`;
        }
      }

      Vue.set(state.usersMap, id, user);
      Vue.set(state.usersMapNames, id, {
        id: user.id,
        name: user.name || null,
        email: user.email,
        _expires: Date.now() + 1000 * 60 * 60 * 24 * 14, // caching for two weeks
      });
    },
  },
  actions: {
    async fetchUser({ commit, state }, { id, fields = [], nocache = 0 }) {
      const decoded = cdlid(id);
      nocache = nocache === false ? 1 : 0;

      if (!decoded.id.includes('cdl/')) {
        return {
          name: { first: decoded.id },
        };
      }
      const localStorageCacheUser = state.usersMapNames[id];
      if (
        !nocache &&
        localStorageCacheUser &&
        fields.length &&
        fields.every((f) => localStorageCacheUser[f] !== undefined) &&
        localStorageCacheUser._expires > Date.now()
      ) {
        return localStorageCacheUser;
      }

      // check if its an admin id
      if (nocache === 1 || !state.usersMap[id]) {
        const promise = this._vm.api
          .get(`/user/${id}/permissions`, { params: { nocache } })
          .then((e) => e.data);
        promise.then((user) => {
          commit('cacheUser', { id, user });
          debouncedUpdateLocalStorageUsers(state.usersMapNames);
        });
        commit('cacheUser', { id, user: promise });
        return promise;
      }

      return Promise.resolve(state.usersMap[id]);
    },
    async getUserAuth(store, { realm, email, password }) {
      return this._vm
        .api({
          method: 'GET',
          url: `/user/auth?realm=${realm}`,
          auth: {
            username: email,
            password: unescape(encodeURIComponent(password)),
          },
        })
        .then((r) => r.data);
    },
    async postUserAuth(store, { refresh_token, realm }) {
      return this._vm.api.post(`/user/auth?realm=${realm}`, { refresh_token }).then((r) => r.data);
    },
  },
};
function updateLocalStorageUsers(users) {
  localStorage.usersMapNames = JSON.stringify(users);
}
const debouncedUpdateLocalStorageUsers = debounce(updateLocalStorageUsers, 2000);
