import { DateTime } from 'luxon';
import ID from '@compassdigital/id';

export const BRAND = 'BRAND';
export const SITE = 'SITE';

export const Realms = {
  bolter: 'weK3E0K4vduLKk86YgvRCzd0z5DaLeu3ggAy',
  cdl: '6MNvqeNgGWSLAv4DoQr7CaKzaNGZl5',
};

export const RecurrenceTypes = {
  Daily: 'Daily',
  Weekday: 'Every weekday',
  NeverRepeat: 'Never repeat',
  Custom: 'Custom',
};

export const DefaultMultigroup = {
  name: 'All',
  id: ID('location', 'cdl', 'multigroup', 'all'),
};

export const CalendarTypes = {
  business: 'business',
  delivery: 'delivery',
  menu: 'menu',
  closure: 'holiday',
};

export const DefaultEventDetails = {
  busy: true,
  calendar: '',
  color: '#1976d2',
  description: '',
  forecolor: '#ffffff',
  icon: '',
  location: '',
  title: '',
};

export const DefaultEventStyle = {
  delivery: {
    calendar: CalendarTypes.delivery,
    title: 'Delivery Hours',
    color: '#1976d2',
  },
  business: {
    calendar: CalendarTypes.business,
    title: 'Pickup Hours',
    color: '#43A047',
  },
  menu: {
    calendar: CalendarTypes.menu,
    title: 'Menu',
    color: '#00897B',
  },
};

export const UserRoles = {
  admin: 'admin',
  sys_admin: 'sys_admin',
  im_user: 'im_user',
  site_operator: 'site_operator',
  menu_user: 'menu_user',
  dc_team: 'dc_team',
  runner: 'runner',
};

export const CustomPermissions = {
  overridePlu: 'override_plu:menu:*',
  renameLocalMenu: 'write_label:menu:*',
  editItemDescription: 'write_item_desc:menu:*',
  editCalories: 'edit_calories:menu:*',
  renameLocalItems: 'write_item_label:menu:*',
  refundOrders: 'write:order:*',
  kickMenu: 'kick:menu:*',
  canAccessReports: 'report:c360:*',
  canAccessProductReport: 'report:c360:product_mix',
  canAccessProductDistribution: 'report:c360:sales_distribution',
  canAccessProductSummary: 'report:c360:sales_summary',
  menuAccess: '*:menu:*',
  editBrandVisibility: 'hide:brand:*',
  toggleMenuEnabled: 'write_menu_enabled:calendar:*',
  allowReadSchedule: 'read_menu_schedule:calendar:*',
  allowSchedule: 'write_menu_schedule:calendar:*',
  assignBrandMenus: 'assign_menu:brand:*',
  editBrandTimeslots: 'edit_timeslots:brand:*',
  readAnalytics: 'read:analytics:*',
  assignAnnouncement: '*:announcement:*',
  viewPaymentInfo: 'read:payment:*',
  canDeleteStations: 'delete:centriclocation:*',
  canWriteDeliverLocations: 'write:deliverydestination:*',
};

export const OrderType = {
  delivery: 'delivery',
  frictionless: 'frictionless',
  pickup: 'pickup',
  scan_and_go: 'scan_and_go',
  all: 'all',
};

export const DefaultAppID = {
  dev: '014B6RP683C2QL7X00oDS86lrKOO14twGXQwzDARCgympJ42Q5SD1LWZ2gppTOGd4eov2GcePXaa59Zquma',
  staging: '11J3gKPg8BCR3mr5OO92S6EBL4ddEAT17G44eoLPSw0N21gy4OHjQXjDG6LXIrL1MY8B5PHPX9omNMrqFJO',
  prod: '11J3gKPg8BCR3mr5OO92S6EBL4ddEAT17G44eoLPSw0N21gy4OHjQXjDG6LXIrL1MY8B5PHPX9omNMrqFJO',
};

export const TypeOfKds = {
  nextep: {
    name: 'Nextep',
    value: 'nextep',
  },
  agilysys: {
    name: 'Agilysys',
    value: 'agilysys',
  },
  volante: {
    name: 'Volante Printer',
    value: 'volante',
  },
  cdl: {
    name: 'CDL KDS',
    value: 'cdl',
  },
};

export const DefaultSchedule = {
  startDate: '',
  endDate: '',
  startEndTimes: [
    {
      startTime: '',
      endTime: '',
      is24Hours: false,
    },
  ],
  days: [],
};

export const VimeoVideoID = {
  stationList: '412472158',
  localMenu: '412470879',
  globalMenu: '415489369#t=4s', // force video to start from the 4th second
};

export const DELIVERY_TIMESLOT_TYPES = {
  automatic: {
    name: 'Automatic',
    value: 'automatic',
  },
  user_defined: {
    name: 'User Generated',
    value: 'user_defined',
  },
};

export const MEALPLAN_TYPES = {
  BLACKBOARD: 'BLACKBOARD',
  BLACKBOARD_SAAS: 'BLACKBOARD SAAS',
  TRANSACT: 'TRANSACT',
  CBORD: 'CBORD',
  ITCSTANDARD: 'ITCSTANDARD',
  ITCDIETARYRESTRICTION: 'ITCDIETARYRESTRICTION',
  ITCLUNCHBOX: 'ITCLUNCHBOX',
  ITCSSO: 'ITCSSO',
  HEARTLAND: 'HEARTLAND',
  ATRIUM: 'ATRIUM',
  CBORD_DIRECT: 'CBORD DIRECT',
};

export const DEFAULT_MEALPLAN_NAMES = {
  ITC: 'ITC',
};

export const TENDER_TYPES = {
  MEAL_EQUIVALENT: 'meal_equivalent',
  DECLINING_BALANCE: 'declining_balance',
  MEAL_EXCHANGE: 'meal_exchange',
};

export const WALLET_TYPES = {
  APPLE_WALLET: 'applewallet',
  GOOGLE_WALLET: 'googlewallet',
};

export const CASHLESS_TENDER_TYPES = [
  {
    text: 'Badge Pay',
    value: 'badge_pay',
  },
  {
    text: 'Coupon Voucher',
    value: 'coupon_voucher',
  },
  {
    text: 'Voucher',
    value: 'voucher',
  },
  {
    text: 'Stipend',
    value: 'stipend',
  },
];

export const ANALYTICS_REPORT_TYPE = {
  sales: 'sales',
  bills: 'bills',
  transactions: 'transactions',
  top_items: 'top-items',
};

export const SMART_ANALYTICS_DATE_PRESETS = {
  today: {
    text: 'Today',
    value: 'today',
    range() {
      const end = DateTime.local().toISO();
      return [end, end];
    },
  },
  yesterday: {
    text: 'Yesterday',
    value: 'yesterday',
    range() {
      const end = DateTime.local()
        .minus({ days: 1 })
        .toISO();
      return [end, end];
    },
  },
  'last-7-days': {
    text: 'Last 7 Days',
    value: 'last-7-days',
    range() {
      const start = DateTime.local()
        .minus({ days: 7 })
        .toISO();
      const end = DateTime.local().toISO();
      return [start, end];
    },
  },
  'last-week': {
    text: 'Last Week',
    value: 'last-week',
    range() {
      const start = DateTime.local()
        .minus({ weeks: 1 })
        .startOf('week')
        .toISO();
      const end = DateTime.local()
        .minus({ weeks: 1 })
        .endOf('week')
        .toISO();
      return [start, end];
    },
  },
  'last-30-days': {
    text: 'Last 30 Days',
    value: 'last-30-days',
    range() {
      const start = DateTime.local()
        .minus({ days: 30 })
        .toISO();
      const end = DateTime.local().toISO();
      return [start, end];
    },
  },
  'last-90-days': {
    text: 'Last 90 Days',
    value: 'last-90-days',
    range() {
      const start = DateTime.local()
        .minus({ days: 90 })
        .toISO();
      const end = DateTime.local().toISO();
      return [start, end];
    },
  },
  'last-month': {
    text: 'Last Month',
    value: 'last-month',
    range() {
      const start = DateTime.local()
        .minus({ month: 1 })
        .startOf('month')
        .toISO();
      const end = DateTime.local()
        .minus({ month: 1 })
        .endOf('month')
        .toISO();
      return [start, end];
    },
  },
  'last-year': {
    text: 'Last Year',
    value: 'last-year',
    range() {
      const start = DateTime.local()
        .minus({ year: 1 })
        .startOf('year')
        .toISO();
      const end = DateTime.local()
        .minus({ year: 1 })
        .endOf('year')
        .toISO();
      return [start, end];
    },
  },
  custom: {
    text: 'Custom',
    value: 'custom',
    range() {
      return [];
    },
  },
};

export const PROMOTION_STATUS = {
  paused: 'paused',
  active: 'active',
  expired: 'expired',
  scheduled: 'scheduled',
};

export const DEFAULT_VENDOR_IDs = {
  ITC: '00000G0SMYPTYJ5LP1Z3C9E2LH5OBCPJ',
};

export const ALLOWED_BUCKET_NAMES = {
  images: 'images.compassdigital.org',
  assets: 'assets.compassdigital.org',
  foodworks_uploads: 'uploads.app.foodworks.org',
  partners: 'cdl-partner-menu-frictionless',
};

export const IMAGE_AGES = {
  new: 'new',
  old: 'old',
};

export const IMAGE_ENTITY_TYPES = {
  menu: 'menu',
};
export const IMAGE_TEMPLATE = {
  menu: {
    src: '',
    sizes: {
      original: '',
      thumbnail_80_80: '',
    },
  },
};
export const IMAGE_TEMPLATE_KEYS = {
  menu: {
    src: 'src',
    original: 'original',
    thumbnail_80_80: 'thumbnail_80_80',
  },
};

export const DEFAULT_JDECONFIG_ID = {
  ID: 'jde-configuration',
};

export const PRICE_RULE = {
  min: 0,
  max: 999.99,
};

export const allowedMultigroupsPromotion = ['boost', 'thrive', 'rogers', 'nourish'];
export const featureFlagNames = {
  oneMarketIntegration: 'cdl-11618-onemarket-integration',
  localMenuGroupToSitePermissions: 'Hide-LMG-Site-Link',
  centricMenusNoAP3: 'Centric-Menus-No-AP3',
};

export const MenuPermissionTypes = {
  globalMenuGroup: 'global_menu_group',
  localMenuGroup: 'local_menu_group',
};
export const MenuRoles = {
  global_menu_group_admin: 'global_menu_group_admin',
  local_menu_group_admin: 'local_menu_group_admin',
  global_menu_group_manager: 'global_menu_group_manager',
  local_menu_group_manager: 'local_menu_group_manager',
};

export const MenuRolePermissions = {
  [MenuRoles.global_menu_group_admin]: [
    `*:menu_v3:role`,
    `${MenuRoles.global_menu_group_admin}:menu_v3:role`,
    `*:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.local_menu_group_admin]: [
    `${MenuRoles.local_menu_group_admin}:menu_v3:role`,
    `${MenuRoles.local_menu_group_manager}:menu_v3:role`,
    `read:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.global_menu_group_manager]: [
    `${MenuRoles.global_menu_group_manager}:menu_v3:role`,
    `read:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.local_menu_group_manager]: [`${MenuRoles.local_menu_group_manager}:menu_v3:role`],
};

export const GlobalMenuGroupPermissions = [
  `write_nested:${MenuPermissionTypes.globalMenuGroup}:{{id}}`,
];

export const LocalMenuGroupPermissions = [
  `write_nested:${MenuPermissionTypes.localMenuGroup}:{{id}}`,
  `read:${MenuPermissionTypes.localMenuGroup}:{{id}}`,
];

export const DefaultPermissions = {
  [UserRoles.admin]: [
    CustomPermissions.canAccessReports,
    CustomPermissions.refundOrders,
    ...MenuRolePermissions[MenuRoles.local_menu_group_admin],
  ],
  [UserRoles.site_operator]: [
    CustomPermissions.canAccessReports,
    CustomPermissions.refundOrders,
    ...MenuRolePermissions[MenuRoles.local_menu_group_manager],
  ],
};

export const defaultAppID = (() => {
  switch (document.location.hostname) {
    case 'adminpanel.compassdigital.org':
      return DefaultAppID.prod;
    case 'staging.adminpanel.compassdigital.org':
      return DefaultAppID.staging;
    default:
      return DefaultAppID.dev;
  }
})();
export const CentricOSBaseURL = {
  local: 'http://localhost:8081',
  dev: 'https://dev.centricos.io',
  staging: 'https://staging.centricos.io',
  production: 'https://centricos.io',
};
