<template>
  <v-btn class="bell-button" flat @click="toggleNotificationPanel(true)">
    <v-icon>mdi-bell-outline</v-icon>
    <div>
      <v-flex :class="unreadNotificationsExist ? 'new-notification' : ''">
        <span class="count" v-if="unreadNotifications.length">
          {{ unreadNotifications.length }}
        </span>
      </v-flex>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    fetch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('notifications', ['unreadNotifications']),
    unreadNotificationsExist() {
      return this.unreadNotifications.length > 0;
    },
  },
  methods: {
    ...mapMutations('adminPanel', ['toggleNotificationPanel']),
    ...mapActions('notifications', ['fetchNotifications']),
  },
  mounted() {
    if (this.fetch) {
      this.fetchNotifications();
    }
  },
};
</script>

<style scoped>
.bell-button {
  position: relative;
  margin: 0.25em 0 0 1em;
}
.new-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2.5em;
  bottom: 1.2em;
  background: tomato;
  border-radius: 2.8em;
  height: auto;
  width: 2.5em;
  font-size: 0.7em;
}
.count {
  margin-top: 0.1em;
  color: #ffffff;
}
</style>
