<template>
  <fragment>
    <v-layout row wrap :class="justify" align-center v-if="loading" :style="style">
      <v-flex shrink>
        <v-progress-circular indeterminate color="primary" :size="size"></v-progress-circular>
      </v-flex>
    </v-layout>
    <slot v-if="!loading"></slot>
  </fragment>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 50,
    },
    mt: {
      type: String,
      default: '0px',
    },
    justify: {
      type: String,
      default: 'justify-center',
    },
  },
  computed: {
    style() {
      return {
        'margin-top': this.mt,
      };
    },
  },
};
</script>

<style scoped>
.load-wrapper {
  flex-grow: 10;
}
</style>
