<template>
  <v-navigation-drawer
    absolute
    temporary
    v-model="notificationPanelProxy"
    right
    width="350"
    height="100vh"
  >
    <v-layout column fill-height>
      <v-flex class="header-box">
        <div @click="notificationPanelProxy = false">
          <notification-count :fetch="false" />
        </div>
        <v-flex class="notification-title" shrink>
          <span class="Subtitle-1-Primary-Left" style="font-size: 16.5px;margin-right: 1em;"
            >Recent Updates</span
          >
        </v-flex>
      </v-flex>
      <v-flex>
        <v-flex v-for="notification in this.getRelevantDateNotifications" :key="notification.id">
          <v-tooltip bottom :disabled="isRead(notification)">
            <template v-slot:activator="{ on }">
              <v-card
                v-on="on"
                @click="markRead(notification)"
                class="notification-card"
                :class="isRead(notification) ? 'notification-card--disabled' : ''"
              >
                <v-flex class="notification-icon">
                  <v-icon :color="iconColor(notification)">mdi-alert-decagram</v-icon>
                </v-flex>
                <v-flex class="card-text-box">
                  <v-flex class="card-text-header-box">
                    <div>
                      {{
                        formatIsoToDate(
                          notification.release_date || notification.date_modified_date,
                        )
                      }}
                    </div>
                    <div>
                      {{ formatIsoToTime(notification.release_date || notification.date_modified) }}
                    </div>
                  </v-flex>
                  <v-flex class="card-text" v-html="notification.text"></v-flex>
                </v-flex>
              </v-card>
            </template>
            <span>Mark as Read</span>
          </v-tooltip>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Vue2Filters from 'vue2-filters';
import notificationCount from '@/components/notifications/notification-count';

export default {
  components: {
    notificationCount,
  },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    valid: false,
  }),
  computed: {
    ...mapState('adminPanel', ['notificationPanel']),
    ...mapState('notifications', ['notifications']),
    ...mapGetters('notifications', ['unreadNotifications']),

    getRelevantDateNotifications() {
      // only want to show notifications in the last 30 days or if unread
      const now = new Date(Date.now()).toISOString();
      const unread_notifications = this.unreadNotifications;
      return this.notifications.filter((n) => {
        const release_date = n.release_date || n.date_modified || now;
        const start_date = new Date(Date.now() - 2592000000).toISOString();
        const in_release_window = release_date <= now && release_date >= start_date;
        const is_unread = unread_notifications.includes(n);
        return is_unread || in_release_window;
      });
    },
    notificationPanelProxy: {
      get() {
        return this.notificationPanel;
      },
      set(v) {
        this.toggleNotificationPanel(v);
      },
    },
  },
  methods: {
    ...mapActions('notifications', ['postNotificationStatus']),
    ...mapMutations('adminPanel', ['toggleNotificationPanel']),
    isRead(notification) {
      return notification && notification.status && !!notification.status.read;
    },
    formatIsoToDate(isoString) {
      return DateTime.fromISO(isoString).toLocaleString(DateTime.DATE_FULL);
    },
    formatIsoToTime(isoString) {
      return DateTime.fromISO(isoString).toLocaleString(DateTime.TIME_SIMPLE);
    },
    async markRead(notification) {
      if (notification.status && notification.status.read) return;
      await this.postNotificationStatus({ ...notification, status: { read: true } });
    },
    iconColor(notification) {
      return !this.isRead(notification) ? '#ff0000' : undefined;
    },
  },
};
</script>

<style scoped>
.notification-title {
  padding: 10px;
  padding-left: 15px;
}
.notification-card {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  cursor: pointer;
}
.notification-card:hover {
  background: #f5f5f5;
}
.notification-card--disabled {
  cursor: default;
  color: #d0cccc;
}
.notification-icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-right: 0.5rem;
}
.header-box {
  width: 100%;
  max-height: 8em;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
}
.card-text-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 87.5%;
  margin-right: 1em;
}
.card-text-header-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
}

.card-text {
  width: 100%;
  color: #000000;
  font-weight: bold;
  max-height: 12rem;
  overflow-y: scroll;
}

.notification-card--disabled.notification-icon i,
.notification-card--disabled.theme--light.v-sheet,
.notification-card--disabled {
  color: #d0cccc;
  font-weight: normal;
}
</style>
