import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VToolbar,
    { attrs: { app: "" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            VBtn,
            {
              attrs: { flat: "", icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("mobileMenuToggle")
                }
              }
            },
            [_c(VIcon, [_vm._v("mdi-menu")])],
            1
          )
        : _vm._e(),
      _c(VSpacer),
      _c(
        VToolbarItems,
        [
          _vm.isSiteOperator || _vm.isImUser
            ? _c(
                "div",
                { staticClass: "logo" },
                [_c(VImg, { attrs: { src: _vm.multigroupLogo } })],
                1
              )
            : _vm._e(),
          _c("notification-count"),
          _c(
            VMenu,
            {
              attrs: { "offset-y": "", "nudge-width": 150 },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        VBtn,
                        _vm._g({ attrs: { flat: "" } }, on),
                        [
                          _c(
                            VLayout,
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(VFlex, { attrs: { xs12: "" } }, [
                                _vm._v(_vm._s(_vm.fullname))
                              ])
                            ],
                            1
                          ),
                          _c(VIcon, [
                            _vm._v("mdi-chevron-down-circle-outline")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                VList,
                [
                  _c(
                    VListTile,
                    {
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [_c(VListTileTitle, [_vm._v("Logout")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }