/* eslint-disable no-loop-func */

const DEFAULT_STATE = {
  announcements: [],
};

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations: {
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },
  },
  getters: {
    getAnnouncements: (state) => (key) => {
      if (!key) return state.announcements || [];
      return state.announcements.filter((e) => e.data && e.data.key === key);
    },
  },
  actions: {
    async fetchAnnouncements({ commit }) {
      const { data } = await this._vm.api.get(`/announcement/resource`);
      const { announcements } = data;
      commit('setAnnouncements', announcements);
    },
  },
};
