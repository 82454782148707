import ID from '@compassdigital/id';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { isDHsite } from '@/helpers/normalizeSites';

const DEFAULT_STATE = {
  active_brand: null,
  active_site: null,
  active_site_config: {
    public: null,
    private: null,
  },
  multigroups: [],
  multigroupMap: {},
  brandMap: {},
  locationMap: {},
  siteMap: {},
  fetchAllPromise: null,
  fetchPaginated: {
    results: [],
  },
};

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations: {
    setBrand(state, brand) {
      state.active_brand = brand;
    },
    updateBrandFrictionless(state, enabled) {
      state.active_brand.is.frictionless_supported = enabled;
    },
    setBrandInMap(state, brand) {
      Vue.set(state.brandMap, brand.id, { ...state.brandMap[brand.id], ...brand });
    },
    setSite(state, site) {
      state.active_site = site;
    },
    setMultigroups(state, multigroups) {
      state.multigroups = multigroups;
    },
    setAllSites(state, sites) {
      state.all_sites = sites;
    },
    setConfig(state, { privateConfig, publicConfig }) {
      state.active_site_config.private = privateConfig;
      state.active_site_config.public = publicConfig;
    },
    setSelectedMultigroup(state, multigroup) {
      state.selected_multigroup = multigroup;
    },
    setLocationInMap(state, location) {
      const mappedLocation = { ...state.locationMap[location.id], ...location };
      const localBrandMap = {};
      if (mappedLocation.brands) {
        mappedLocation.brands = mappedLocation.brands
          .map((brand) => {
            localBrandMap[brand.id] = { ...state.brandMap[brand.id], ...brand };
            if (mappedLocation.hasReadPermissions && !localBrandMap[brand.id].hasReadPermissions) {
              // we need to filter out brands that user don't have permissions to
              return false;
            }
            return localBrandMap[brand.id];
          })
          .filter(Boolean);
      }
      Vue.set(state.locationMap, location.id, mappedLocation);
      Vue.set(state, 'brandMap', { ...state.brandMap, ...localBrandMap });
    },
    setSiteInMap(state, site) {
      const mappedSite = { ...state.siteMap[site.id], ...site };
      if (!mappedSite.brands) {
        mappedSite.brands = {};
      }
      const localLocationMap = {};
      const localBrandMap = {};
      if (Array.isArray(mappedSite.locations)) {
        mappedSite.locations = mappedSite.locations
          .map((location) => {
            const mergedLocation = { ...state.locationMap[location.id], ...location };
            if (mappedSite.hasReadPermissions && !mergedLocation.hasReadPermissions) {
              // we need to filter out locations that user don't have permissions to
              return false;
            }
            if (mergedLocation.brands) {
              mergedLocation.brands = mergedLocation.brands
                .map((brand) => {
                  localBrandMap[brand.id] = { ...state.brandMap[brand.id], ...brand };
                  if (
                    mappedSite.hasReadPermissions &&
                    !localBrandMap[brand.id].hasReadPermissions
                  ) {
                    // we need to filter out brands that user don't have permissions to
                    return false;
                  }
                  mappedSite.brands[brand.id] = localBrandMap[brand.id];
                  return localBrandMap[brand.id];
                })
                .filter(Boolean);
            }
            localLocationMap[location.id] = mergedLocation;
            return localLocationMap[location.id];
          })
          .filter(Boolean);
      }
      Vue.set(state.siteMap, site.id, mappedSite);
      Vue.set(state, 'locationMap', { ...state.locationMap, ...localLocationMap });
      Vue.set(state, 'brandMap', { ...state.brandMap, ...localBrandMap });
    },
    setFetchPaginatedData(state, paginatedData) {
      Vue.set(state, 'fetchPaginated', paginatedData);
    },
    setMultigroupsMap(state, multigroups) {
      const localMultigroupMap = {};
      const localSiteMap = {};
      const localLocationMap = {};
      const localBrandMap = {};
      multigroups.forEach((multigroup) => {
        if (!multigroup || !multigroup.id) return;
        localMultigroupMap[multigroup.id] = {
          ...state.multigroupMap[multigroup.id],
          ...multigroup,
        };
        const parentMultigroup = { id: multigroup.id, name: multigroup.name };
        multigroup.groups.forEach((site) => {
          site.parentMultigroup = parentMultigroup;
          site.hasReadPermissions = true;
          site.brands = {};
          localSiteMap[site.id] = { ...state.siteMap[site.id], ...site };
          if (!localSiteMap[site.id].locations) return;
          const parentSite = { id: site.id, name: site.name };
          localSiteMap[site.id].locations = localSiteMap[site.id].locations
            .map((location) => {
              location.parentMultigroup = parentMultigroup;
              location.parentSite = parentSite;
              location.hasReadPermissions = true;
              localLocationMap[location.id] = { ...state.locationMap[location.id], ...location };
              if (localLocationMap[location.id].brands) {
                const parentLocation = { id: location.id, name: location.name };
                localLocationMap[location.id].brands = localLocationMap[location.id].brands
                  .map((brand) => {
                    brand.parentMultigroup = parentMultigroup;
                    brand.parentSite = parentSite;
                    brand.parentLocation = parentLocation;
                    brand.hasReadPermissions = true;
                    localBrandMap[brand.id] = { ...state.brandMap[brand.id], ...brand };
                    localSiteMap[site.id].brands[brand.id] = localBrandMap[brand.id];
                    return localBrandMap[brand.id];
                  })
                  .filter((brand) => brand.hasReadPermissions);
              }
              return localLocationMap[location.id];
            })
            .filter((location) => location.hasReadPermissions);
        });
      });
      Vue.set(state, 'multigroupMap', { ...state.multigroupMap, ...localMultigroupMap });
      Vue.set(state, 'siteMap', { ...state.siteMap, ...localSiteMap });
      Vue.set(state, 'locationMap', { ...state.locationMap, ...localLocationMap });
      Vue.set(state, 'brandMap', { ...state.brandMap, ...localBrandMap });
    },
    clear(state) {
      state.active_brand = null;
      state.active_site = null;
      state.all_sites = null;
      Vue.set(state, 'multigroupMap', {});
      Vue.set(state, 'siteMap', {});
      Vue.set(state, 'locationMap', {});
      Vue.set(state, 'brandMap', {});
      state.active_site = null;
      state.active_site_config = {
        public: null,
        private: null,
      };
    },
    storeAllPromise(state, promise) {
      state.fetchAllPromise = promise;
    },
  },
  getters: {
    getCdlMultigroups: (state) =>
      state.multigroups.filter((e) => {
        if (e.name.match(/unit_test|All/)) return false;
        if (ID(e.id).provider === 'cdl') return true;
        return false;
      }),
    isDHBrand: () => (brand = {}) => {
      const decoded_id = ID(brand.id);
      return (
        (decoded_id && decoded_id.provider === 'dh' && (!brand.meta || !brand.meta.migrated)) ||
        false
      );
    },
    isDHSite: () => isDHsite,
    activeMultigroup: (state) => {
      return (
        state.siteMap &&
        state.siteMap[state.active_site && state.active_site.id] &&
        state.siteMap[state.active_site.id].parentMultigroup
      );
    },
  },
  actions: {
    async fetchBrand({ commit, state, dispatch }, { id, nocache }) {
      if (state.active_brand && state.active_brand.id !== id) {
        commit('setBrand', null);
      }
      const brand = await dispatch('getLocationBrand', { id, nocache });
      commit('setBrand', brand);
    },
    async getLocationBrand({ commit, state }, { id, nocache }) {
      const cachedBrand = state.brandMap[id];
      const isFullBrand = cachedBrand && !!cachedBrand.hours;
      if (cachedBrand && isFullBrand && !nocache) {
        return cloneDeep(cachedBrand);
      }
      if (nocache) {
        // refreshing cache since with/without `extended` are two different caches
        // no await needed
        this._vm.api
          .get(`/location/brand/${id}`, {
            params: { nocache },
          })
          .catch((err) => {
            console.error(`Failed to update brand cache for id ${id}`, err);
          });
      }
      const { data } = await this._vm.api.get(`/location/brand/${id}`, {
        params: {
          nocache,
          extended: true,
        },
      });
      const copy = cloneDeep(data);
      commit('setBrandInMap', data);
      return copy;
    },
    async putLocationBrand({ commit }, brand) {
      const { data } = await this._vm.api.put(`/location/brand/${brand.id}`, brand);
      const copy = cloneDeep(data);
      commit('setBrandInMap', data);
      return copy;
    },
    async patchLocationBrand({ commit }, brand) {
      const { data } = await this._vm.api.patch(`/location/brand/${brand.id}`, brand);
      const copy = cloneDeep(data);
      commit('setBrandInMap', data);
      return copy;
    },
    async postLocationBrand({ commit }, brand) {
      const { data } = await this._vm.api.post(`/location/brand`, brand);
      commit('setBrandInMap', { ...data, hasReadPermissions: true });
      return cloneDeep(data);
    },
    async postLocation({ commit }, location) {
      const { data } = await this._vm.api.post(`/location`, location);
      commit('setLocationInMap', { ...data, hasReadPermissions: true });
      return cloneDeep(data);
    },
    async getLocation({ state, commit }, { id, nocache }) {
      const cachedLocation = state.locationMap[id];
      const isFullLocation = cachedLocation && !!cachedLocation.address;
      if (cachedLocation && isFullLocation && !nocache) {
        return cloneDeep(cachedLocation);
      }
      if (nocache) {
        // refreshing cache since with/without `extended` are two different caches
        // no await needed
        this._vm.api
          .get(`/location/${id}`, {
            params: { nocache },
          })
          .catch((err) => {
            console.error(`Failed to update location cache for id ${id}`, err);
          });
      }
      const { data } = await this._vm.api.get(`/location/${id}`, {
        params: {
          nocache,
          extended: true,
        },
      });
      const copy = cloneDeep(data);
      commit('setLocationInMap', data);
      return copy;
    },
    async patchLocation({ commit }, location) {
      const { data } = await this._vm.api.patch(`/location/${location.id}`, location);
      const copy = cloneDeep(data);
      commit('setLocationInMap', data);
      return copy;
    },
    async fetchSite(
      { commit, state, dispatch },
      { id, multigroupId, nocache, fetchWithPermissions = false },
    ) {
      if (state.active_site && state.active_site.id !== id) {
        commit('setSite', null);
        commit('setBrand', null);
      }

      let activeSite = dispatch('getLocationGroup', { id, nocache }).catch(() =>
        dispatch('getLocationGroup', { id, nocache }),
      );

      try {
        if (fetchWithPermissions) {
          // fetch the group based on your permissions
          const multigroup = await dispatch('fetchMultigroup', {
            id: multigroupId,
            nocache,
          });
          activeSite = await activeSite;
          const authorizedSite = multigroup.groups && multigroup.groups.find((g) => g.id === id);
          if (authorizedSite) {
            activeSite = {
              ...activeSite,
              ...authorizedSite,
              locations:
                authorizedSite.locations &&
                authorizedSite.locations.map((l) => {
                  const location =
                    activeSite &&
                    activeSite.locations &&
                    activeSite.locations.find((ll) => l.id === ll.id);
                  return {
                    ...location,
                    brands:
                      l &&
                      l.brands &&
                      location &&
                      location.brands &&
                      location.brands.filter((b) => !!l.brands.find((bb) => b.id === bb.id)),
                  };
                }),
            };
          }
        } else {
          activeSite = await activeSite;
        }
      } catch (err) {
        console.error(err);
        activeSite = null;
      }

      commit('setSite', activeSite);
    },
    async getLocationGroup({ state, commit }, { id, nocache }) {
      const include_estimated_wait_time = false;
      const cachedGroup = state.siteMap[id];
      const isFullGroup =
        cachedGroup &&
        !!cachedGroup.address &&
        cachedGroup.locations &&
        cachedGroup.locations.every((location) => location.location_multigroup);
      if (cachedGroup && isFullGroup && !nocache) {
        return cloneDeep(cachedGroup);
      }
      if (nocache) {
        // refreshing cache since with/without `extended` are two different caches
        // no await needed
        this._vm.api
          .get(`/location/group/${id}`, {
            params: { nocache, include_estimated_wait_time },
          })
          .catch((err) => {
            console.error(`Failed to update group cache for id ${id}`, err);
          });
        // clearing the cache for the endpoint that Mobile app uses
        this._vm.api
          .get(`/location/group/${id}`, {
            params: { nocache, include_brands_config: true, include_estimated_wait_time },
          })
          .catch((err) => {
            console.error(`Failed to update group cache for id ${id}`, err);
          });
      }
      const { data } = await this._vm.api.get(`/location/group/${id}`, {
        params: {
          nocache,
          extended: true,
          include_estimated_wait_time,
        },
      });
      const copy = cloneDeep(data);
      commit('setSiteInMap', data);
      return copy;
    },
    async postLocationGroup({ commit }, group) {
      const { data } = await this._vm.api.post(`/location/group`, group);
      commit('setSiteInMap', { ...data, hasReadPermissions: true });
      return cloneDeep(data);
    },
    async patchLocationGroup({ commit }, group) {
      const { data } = await this._vm.api.patch(`/location/group/${group.id}`, group);
      const copy = cloneDeep(data);
      commit('setSiteInMap', data);
      return copy;
    },
    async fetchMultigroups({ commit }) {
      const { data } = await this._vm.api.get('/location/multigroup').catch((err) => {
        console.error('could not fetch multigroups', err);
      });
      // filter inactive, unit tests and nonCDL multigroups
      const multigroups = data.multigroups
        .filter((m) => m.name !== 'BAMCO' && m.name !== 'BoostSelect')
        .filter((m) => !m.name.match(/unit_test/) && ID(m.id).provider === 'cdl');

      commit('setMultigroups', multigroups);
    },
    async fetchMultigroupExpanded({ state, rootState }, { id, nocache = false, userId = null }) {
      let result;
      if (!id) return undefined;
      if (nocache === false || nocache === 0) {
        nocache = null;
      }
      if (!state.fetchAllPromise) {
        result = await this._vm.api
          .get(`/location/multigroup/${id}/user/${userId || rootState.adminPanel.active_user_id}`, {
            params: {
              expanded: true,
              nocache,
            },
          })
          .then((data) => {
            // sometimes it could return string - should be solved though in core service lib
            if (data && typeof data === 'object') return data;
            console.error('could not fetch multigroup expanded on ', data);
            return this._vm.api
              .get(
                `/location/multigroup/${id}/user/${userId || rootState.adminPanel.active_user_id}`,
                {
                  params: {
                    expanded: true,
                  },
                },
              )
              .catch(() => {});
          })
          .catch((err) => {
            console.error('could not fetch multigroup expanded on ', err);
            return this._vm.api
              .get(
                `/location/multigroup/${id}/user/${userId || rootState.adminPanel.active_user_id}`,
                {
                  params: {
                    expanded: true,
                  },
                },
              )
              .catch(() => {});
          });

        if (!result || !result.data) return undefined;
        result.data.id = id;
        return result.data;
      }
      // If fetchAll is in progress, take the result from there
      result = await state.fetchAllPromise.then((multigroups) => {
        const item = multigroups.filter((group) => group.id === id);
        if (!item) return undefined;

        // To match the structure of above, take out the name
        const { name, ...otherKeys } = item;
        return otherKeys;
      });

      return result;
    },
    async getLocationMultigroup(state, { id, ...params }) {
      const { data } = await this._vm.api.get(`/location/multigroup/${id}`, {
        params,
      });
      return data;
    },
    async fetchMultigroup({ dispatch, commit, state }, { id, nocache = false }) {
      const cachedMultigroup = state.multigroupMap[id];
      if (!nocache && cachedMultigroup && cachedMultigroup.groups) {
        return cachedMultigroup;
      }
      const multigroup = await dispatch('fetchMultigroupExpanded', { id, nocache });
      const multigroupWithName = state.multigroups.find((m) => m.id === id);
      if (multigroupWithName) {
        multigroup.name = multigroupWithName.name;
      }
      commit('setMultigroupsMap', [multigroup]);
      return state.multigroupMap[id];
    },
    async patchLocationMultigroup({ commit }, multigroup) {
      const { data } = await this._vm.api.patch(
        `/location/multigroup/${multigroup.id}`,
        multigroup,
      );
      const copy = cloneDeep(data);
      commit('setSiteInMap', data);
      return copy;
    },
    async fetchPaginated(
      { state, commit },
      options = { nocache: false, id: null, limit: 10, filter: null },
    ) {
      const ids = options.id
        ? [options.id.toString()]
        : state.multigroups.map((multigroup) => multigroup.id.toString());

      const response = await this._vm.api.get('location/v2/groups', {
        params: {
          nocache: options.nocache,
          expanded: true,
          page: options.page || 1,
          limit: options.limit || 10,
          filter: options.filter,
          id_multigroups: JSON.stringify(ids),
        },
      });

      commit('setFetchPaginatedData', response.data);
    },
    async fetchAll({ state, dispatch, commit }, options = { nocache: false }) {
      if (!state.fetchAllPromise) {
        if (options.nocache) {
          commit('clear');
        }
        const requests = Promise.all(
          state.multigroups.map((multigroup) =>
            dispatch('fetchMultigroup', {
              id: multigroup.id,
              nocache: options.nocache,
            }),
          ),
        );
        requests.then(() => {
          commit('storeAllPromise', null);
        });
        commit('storeAllPromise', requests);
        return 'done';
      }
      return state.fetchAllPromise;
    },
    async getConfigPublic(store, { id }) {
      const { data } = await this._vm.api.get(`/config/public/${id}`);
      return data;
    },
    async isSiteFrictionless({ dispatch }, siteId) {
      const { groups } = await dispatch('getConfigPublic', { id: 'cognition' });
      return siteId ? groups.includes(siteId) : false;
    },
    async postConfigPublic(store, { id, config }) {
      const { data } = await this._vm.api.post(`/config/public/${id}`, config);
      return data;
    },
    async getConfigPrivate(store, { id }) {
      const { data } = await this._vm.api.get(`/config/${id}`);
      return data;
    },
    async postConfigPrivate(store, { id, config }) {
      const { data } = await this._vm.api.post(`/config/${id}`, config);
      return data;
    },
    async getDeliveryDestinations(store, { id }) {
      const { data } = await this._vm.api.get(`/location/group/${id}/deliverydestination`);
      return data.delivery_destinations;
    },
    async postDeliveryDestination(store, payload) {
      const request = await this._vm.api.post(
        `/location/group/${payload.groupId}/deliverydestination`,
        payload.deliveryDestination,
      );
      return request.data;
    },
    async patchDeliveryDestination(store, payload) {
      const request = await this._vm.api.patch(
        `/location/group/${payload.groupId}/deliverydestination`,
        payload.deliveryDestinations,
      );
      return request.data;
    },
    async deleteDeliveryDestination(store, payload) {
      const request = await this._vm.api.delete(
        `/location/group/${payload.groupId}/deliverydestination`,
        {
          data: payload.deliveryDestinationIds,
        },
      );
      return request.data;
    },
    async getJDEConfig(store, costcenter) {
      // GET JDE config
      const jdeConfig = await this._vm.api.get(`/config/jde-configuration`);

      const py = jdeConfig.data.staging.units.includes(costcenter);
      const prod = jdeConfig.data.v1.units.includes(costcenter);

      if (prod && py) {
        return 'PY & Production';
      }
      if (py) {
        return 'PY';
      }
      if (prod) {
        return 'Production';
      }
      return null;
    },
    async validatePaymentInfo(store, { store_id, terminal_id }) {
      const { data } = await this._vm.api.post(`/payment/configvalidate`, {
        store_id,
        terminal_id,
      });

      return data;
    },
  },
};
