/* eslint-disable no-loop-func */
import ID from '@compassdigital/id';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import { DefaultEventDetails, DefaultEventStyle } from '@/constants';

const DEFAULT_STATE = {
  events: [],
  isActiveBrandCalendarFetched: false,
  updatedCalendar: {},
};

const helper = {
  convertToDayspan(event, timezone, config) {
    const [start, end] = event.hours.split('-');
    let dtStartUTC;
    let dtEndUTC;

    const eventTZ = timezone || 'UTC';
    // determine if event is created for the day or for specific date
    if (event.day) {
      dtStartUTC = DateTime.fromFormat(start, 'HH:mm', { zone: 'UTC' }).set({
        weekday: event.day.start,
      });
      dtEndUTC = DateTime.fromFormat(end, 'HH:mm', { zone: 'UTC' }).set({ weekday: event.day.end });
    } else if (event.date) {
      dtStartUTC = DateTime.fromFormat(`${event.date.start} ${start}`, 'yyyy-LL-dd HH:mm', {
        zone: 'UTC',
      });
      dtEndUTC = DateTime.fromFormat(`${event.date.end} ${end}`, 'yyyy-LL-dd HH:mm', {
        zone: 'UTC',
      });
    }
    const sDuration = dtEndUTC.diff(dtStartUTC, 'hours');
    const duration = sDuration.values.hours;

    const dtStartTZ = dtStartUTC.setZone(eventTZ);

    const dayspanEvent = {
      data: { ...DefaultEventDetails, ...config },
      schedule: {
        duration,
        times: [dtStartTZ.toFormat('HH:mm')],
      },
    };

    if (event.day) {
      dayspanEvent.schedule.dayOfWeek = [dtStartTZ.weekday === 7 ? 0 : dtStartTZ.weekday];
    } else if (event.date) {
      dayspanEvent.schedule.dayOfYear = [dtStartTZ.ordinal];
      dayspanEvent.schedule.year = [dtStartTZ.year];
    }

    return dayspanEvent;
  },
  getLabel(label) {
    if (typeof label === 'string') {
      return label;
    }

    if (label.en) {
      return label.en;
    }

    return 'no label found';
  },
};

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    setActiveBrandCalendarFetched(state, value) {
      state.isActiveBrandCalendarFetched = value;
    },
    setUpdatedCalendar(state, updatedCalendar) {
      state.updatedCalendar = updatedCalendar;
    },
  },
  getters: {
    getEvents: (state) => (type) => {
      if (!type) return state.events;
      return state.events.filter((e) => e.data && e.data.calendar === type);
    },
  },
  actions: {
    async fetchCalendarEvents({ commit, dispatch, rootState }) {
      commit('setActiveBrandCalendarFetched', false);
      const { id, timezone, hours, deliveryHours, menus } = rootState.sites.active_brand;
      const calendar = await dispatch('getCalendar', { id, nocache: true });
      let { events } = calendar;
      if (!Array.isArray(events)) {
        events = [];
      }

      // If calendar events dont exist pull from API
      if (!(events && events.length > 0)) {
        // build business hours
        for (const event of hours) {
          if (event.hours) {
            events.push(helper.convertToDayspan(event, timezone, DefaultEventStyle.business));
          }
        }

        // build delivery hours
        for (const event of deliveryHours) {
          if (event && event.hours) {
            events.push(helper.convertToDayspan(event, timezone, DefaultEventStyle.delivery));
          }
        }
        // build menu hours
        if (menus) {
          for (const menuEvent of menus) {
            const label =
              menuEvent.label && menuEvent.label.en
                ? menuEvent.label.en
                : `Menu (${menuEvent.id.substring(0, 4)})`;
            const details = {
              ...DefaultEventStyle.menu,
              title: label,
              menu: menuEvent.id,
              is: {
                pickup: menuEvent.is_pickup,
                delivery: menuEvent.is_delivery,
                frictionless: menuEvent.is_frictionless,
              },
            };
            if (menuEvent && menuEvent.hours) {
              menuEvent.hours.forEach((menuHour) => {
                events.push(helper.convertToDayspan(menuHour, timezone, details));
              });
            } else {
              events.push({
                data: { ...DefaultEventDetails, ...details },
                schedule: {}, // defaults to always
              });
            }
          }
        }
      } else {
        // try to resolve the new menu names if possible
        const eventsCopy = cloneDeep(events);
        // no await since not critical data
        Promise.all(
          eventsCopy
            .filter((event) => event.data.menu)
            .map(async (event) => {
              const menu_id_parts = ID(event.data.menu);
              if (!menu_id_parts || menu_id_parts.provider !== 'cdl') {
                return event;
              }
              const { label } = await dispatch(
                'menus/fetchMenu',
                { id: event.data.menu, nocache: true },
                { root: true },
              );
              event.data.title = (label && label.en) || event.data.title;
              return event;
            }),
        ).then((menu_events) => {
          if (!menu_events || !menu_events.length) return;
          const should_set_events_again = menu_events.some((menu_event) =>
            events.find(
              (e) =>
                e.data &&
                menu_event.data &&
                e.data.menu === menu_event.data.menu &&
                e.data.title !== menu_event.data.title,
            ),
          );
          if (should_set_events_again) {
            commit('setEvents', eventsCopy);
          }
        });
      }

      commit('setEvents', events);
      commit('setActiveBrandCalendarFetched', true);
    },
    async getCalendar({ state }, { id, nocache = false }) {
      if (
        !nocache &&
        state.updatedCalendar &&
        state.updatedCalendar.id &&
        state.updatedCalendar.id === id
      ) {
        return state.updatedCalendar;
      }
      const { data } = await this._vm.api.get(`/calendar/${id}`, {
        params: {
          nocache,
        },
      });
      return data;
    },
    async saveEvents({ commit, state, rootState, dispatch }, { type, events }) {
      const brandId = rootState.sites.active_brand.id;
      // in duration is not set set to 60
      const eventsCopy =
        events &&
        events.map((event) => {
          const { schedule } = event;
          if (!schedule.duration) {
            schedule.duration = 1; // default duration unit is one hour
          }
          return event;
        });

      if (!eventsCopy) return;

      if (type) {
        const otherEvents = state.events.filter((e) => !(e.data && e.data.calendar === type));
        eventsCopy.push(...otherEvents);
      }

      try {
        const { data } = await this._vm.api.put(`/calendar/${brandId}`, {
          events: eventsCopy,
        });
        commit('setUpdatedCalendar', data);
        // to clear the cache
        dispatch('sites/fetchBrand', { id: brandId, nocache: 1 }, { root: true });
        dispatch('getCalendar', { id: brandId, nocache: true });

        commit('setEvents', eventsCopy);
      } catch (err) {
        console.error(err);
        throw new Error('Error occurred while saving calendar events.');
      }
    },
  },
};
