<template>
  <v-footer fixed height="60">
    <v-layout row reverse>
      <v-flex xs1></v-flex>
      <v-flex shrink offset-xs1>
        <v-btn flat color="blue" @click="cancelAction">{{ cancelLabel }}</v-btn>
        <v-btn color="primary" @click="saveActionLoad" :loading="savingLoading"
          ><v-icon class="mr-1" v-if="showCreateIcon">mdi-content-save</v-icon
          >{{ saveLabel }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  props: {
    saveLabel: {
      type: String,
      default: 'Save',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    saveAction: {
      type: Function,
      default() {},
    },
    cancelAction: {
      type: Function,
      default() {},
    },
    showCreateIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    savingLoading: false,
    cancelLoading: false,
  }),
  methods: {
    async saveActionLoad() {
      this.savingLoading = true;
      await this.saveAction();
      this.savingLoading = false;
    },
  },
};
</script>

<style></style>
