// components
import semverCompare from 'semver-compare';
import toggle from '@/components/toggle';
import loading from '@/components/loading';
import construction from '@/components/construction';

const adminPanelPlugin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        checkVersion(featureVersion) {
          if (!featureVersion) {
            return true;
          }
          if (semverCompare(process.env.VERSION, featureVersion) >= 0) return true;
          return false;
        },
        checkUAC(permission, access) {
          if (!permission) return false;
          return Object.keys(permission).some(
            (key) =>
              this.$store.getters['adminPanel/hasRole'](key, true) &&
              permission[key].includes(access),
          );
        },
        canRead(permission) {
          return this.checkUAC(permission, 'r');
        },
        canWrite(permission) {
          return this.checkUAC(permission, 'w');
        },
      },
    });
    Vue.component('toggle', toggle);
    Vue.component('loading-wrapper', loading);
    Vue.component('construction', construction);
  },
};
export default adminPanelPlugin;
