const DEFAULT_STATE = false;

export default {
  namespaced: true,
  state: {
    isInValidMeq: DEFAULT_STATE,
  },
  mutations: {
    setInValidMeq(state, meq) {
      if (
        meq &&
        meq.private &&
        meq.private.type === 'meal_equivalent' &&
        (meq.private.max_swipes_per_transaction === null || meq.private.name === null)
      ) {
        state.isInValidMeq = true;
      } else if (
        meq.max_swipes_per_transaction === null &&
        meq.type === 'meal_equivalent' &&
        meq.rates[0].hours === null
      ) {
        state.isInValidMeq = true;
      } else {
        state.isInValidMeq = false;
      }
    },
  },
};
