import { DateTime } from 'luxon';

const { isEqual } = require('lodash');

export default {
  namespaced: true,
  state: {
    siteID: null,
    analytics: {},
    currencySelected: [],
    brandsSelected: [],
    orderTypeSelected: [],
    isFetching: false,
  },
  mutations: {
    setAnalytics(state, _analytics) {
      if (_analytics === {}) state.analytics = _analytics;
      if (!state.analytics[_analytics.location_group])
        state.analytics[_analytics.location_group] = [];
      state.analytics[_analytics.location_group].push(_analytics.temp);
    },
    setSiteID(state, _siteID) {
      state.siteID = _siteID;
    },
    setBrandsSelected(state, _brandsSelected) {
      state.brandsSelected = _brandsSelected;
    },
    setOrderTypeSelected(state, _orderTypeSelected) {
      state.orderTypeSelected = _orderTypeSelected;
    },
    setCurrencySelected(state, _currencySelected) {
      state.currencySelected = _currencySelected;
    },
    setIsFetching(state, _isFetching) {
      state.isFetching = _isFetching;
    },
  },
  actions: {
    clearCache({ commit }) {
      commit('setSiteID', null);
      commit('setAnalytics', {});
      commit('setCurrencySelected', []);
      commit('setBrandsSelected', []);
      commit('setOrderTypeSelected', []);
      commit('setIsFetching', false);
    },
    async fetchAnalytics(
      { commit, state },
      { brand_ids, location_group, time_frame, dates, order_type },
    ) {
      commit('setIsFetching', true);
      let analytics = {
        avg_bill: 0,
        top_items: [],
        dates: [],
        series: [],
        years: '',
        sales_total: 0,
        transactions_total: 0,
      };
      if (!brand_ids.length) {
        commit('setIsFetching', false);
        return analytics;
      }
      if (state.analytics[location_group]) {
        const result = state.analytics[location_group].find(
          (report) =>
            isEqual(report.dates, dates) &&
            isEqual(report.brand_ids, brand_ids) &&
            isEqual(report.order_type, order_type) &&
            isEqual(report.time_frame, time_frame),
        );
        commit('setIsFetching', false);
        if (result) return result.data;
      }
      const response = await this._vm.api
        .get(`/report/analytics/combined/group/${location_group}`, {
          params: {
            brand_ids: brand_ids.join(','),
            time_frame,
            order_type,
            start_date: DateTime.fromISO(dates.start)
              .toFormat('yyyy-MM-dd')
              .toString(),
            end_date: DateTime.fromISO(dates.end)
              .toFormat('yyyy-MM-dd')
              .toString(),
          },
        })
        .then((resp) => resp && resp.data && resp.data.analytics)
        .catch((err) => {
          commit('setIsFetching', false);
          console.error(err);
        });
      if (response) {
        analytics = response;
        commit('setAnalytics', {
          location_group,
          temp: { dates, brand_ids, order_type, time_frame, data: analytics },
        });
      }
      commit('setIsFetching', false);
      return analytics;
    },
  },
};
