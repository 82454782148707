import { mergeWith } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export function mergeRecursive(destination_record = {}, source_record = {}) {
  mergeWith(destination_record, source_record, (dest_value, src_value) => {
    const is_dest_value_array = Array.isArray(dest_value);
    const is_src_value_array = Array.isArray(src_value);
    if (!is_dest_value_array && !is_src_value_array) return undefined; // will merge default way
    if (is_src_value_array && !is_dest_value_array) return src_value;
    if (is_dest_value_array && !is_src_value_array) return dest_value;
    // both are arrays
    const is_id_based_array = dest_value.some((i) => i && i.id) || src_value.some((i) => i && i.id);
    if (!is_id_based_array) return src_value; // overwrite the whole old value
    src_value.forEach((src_item) => {
      const dest_item_index = dest_value.findIndex((i) => i.id === src_item.id);
      if (dest_item_index === -1) {
        dest_value.push(src_item); // if new has smth that old doesn't - add it
        return;
      }
      if (src_item._delete) {
        // _delete - is a private prop to indicate that record should be deleted
        dest_value.splice(dest_item_index, 1);
        return;
      }
      mergeRecursive(dest_value[dest_item_index], src_item);
    });
    return dest_value.filter(Boolean);
  });
  return destination_record;
}
