import Vue from 'vue';
import Vuex from 'vuex';

import adminPanel from './modules/adminPanel';
import sites from './modules/sites';
import users from './modules/users';
import sectors from './modules/sectors';
import menus from './modules/menus';
import calendar from './modules/calendar';
import reports from './modules/reports';
import announcements from './modules/announcements';
import promotions from './modules/promotions';
import notifications from './modules/notifications';
import analytics from './modules/analytics';
import validateMeqSetup from './modules/validateMeqSetup';
import file from './modules/file';
import splitio from './modules/splitio';
import centricOSMenu from './modules/centricOSMenu';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    adminPanel,
    sites,
    users,
    sectors,
    menus,
    calendar,
    reports,
    announcements,
    notifications,
    analytics,
    promotions,
    validateMeqSetup,
    file,
    splitio,
    centricOSMenu,
  },
  state: {},
  mutations: {},
  actions: {},
  strict: true,
});
