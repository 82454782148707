import store from '@/store';
import { UserRoles } from '@/constants';

const mappedGetters = {
  get getUser() {
    return store.getters['adminPanel/getUser'];
  },
  get hasRole() {
    return store.getters['adminPanel/hasRole'];
  },
  get getRole() {
    return store.getters['adminPanel/getRole'];
  },
  get getPermissions() {
    return store.getters['adminPanel/getPermissions'];
  },
  get isAdmin() {
    return store.getters['adminPanel/isAdmin'];
  },
};

export default {
  getUserSiteIds() {
    if (mappedGetters.isAdmin || mappedGetters.hasRole(UserRoles.sys_admin)) return 'All';
    return mappedGetters.getPermissions
      .filter((scope) => {
        return (
          scope.includes(UserRoles.site_operator) ||
          scope.includes(UserRoles.im_user) ||
          scope.includes(UserRoles.menu_user) ||
          scope.includes(UserRoles.dc_team) ||
          scope.includes(UserRoles.runner)
        );
      })
      .map((scopeId) => {
        return scopeId.split(':')[2];
      });
  },
  async getUserSiteNames() {
    const promises = [];
    const userSiteIds = this.getUserSiteIds();
    if (userSiteIds === 'All') return userSiteIds;
    userSiteIds.forEach(async (siteId) => {
      promises.push(this.api.get(`/location/group/${siteId}`));
    });
    const sitesData = await Promise.all(promises);
    return sitesData.map(({ data: userSite }) => userSite.name || 'None').join(',');
  },
};
