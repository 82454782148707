<template>
  <v-navigation-drawer
    app
    dark
    id="main-nav"
    class="primary darken-5"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown ? true : !mini"
    :mini-variant.sync="mini"
    :value="mobileDrawer"
    @input="$emit('drawerStatus', $event)"
    @mouseover.native="mini = false"
    @mouseleave.native="mini = true"
    style="z-index: 100"
  >
    <v-list style="display: flex; flex-direction: column; height: 100%">
      <v-list-tile>
        <v-list-tile-action>
          <img :src="require('@/assets/logos/cdl-icon-white.png')" />
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title class="main-title">Admin Panel</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile style="height: 30px"></v-list-tile>
      <template v-for="(page, index) in pages">
        <v-list-tile
          :key="index"
          @click="goToPage(page)"
          :class="linkClass(page.route_name)"
          :disabled="linkClass(page.route_name).active"
          ripple
          v-if="permissionCheck(page.permission) && checkVersion(page.version)"
        >
          <v-list-tile-action>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ page.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <v-spacer />
      <v-list-tile>{{ version }}</v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { UserRoles, CustomPermissions } from '@/constants';

export default {
  props: {
    mobileDrawer: {
      type: Boolean,
    },
  },
  data: () => ({
    mini: true,
    version: process.env.VERSION,
    pages: [
      {
        title: 'Dashboard',
        icon: 'mdi-home',
        route_name: '/',
      },
      {
        title: 'Sites',
        icon: 'mdi-map-marker',
        route_name: '/sites',
      },
      {
        title: 'Group Management',
        icon: 'mdi-lan',
        route_name: '/sectors',
        permission: {
          roles: [UserRoles.admin],
        },
      },
      {
        title: 'Menu Manager',
        icon: 'mdi-clipboard-outline',
        route_name: '/menu',
        permission: {
          roles: [UserRoles.admin],
          rule: CustomPermissions.menuAccess,
        },
        customRoutes: [
          {
            path: '/menu/site',
            rule: CustomPermissions.menuAccess,
          },
        ],
      },
      {
        title: 'Orders',
        icon: 'mdi-file-document-box-multiple',
        route_name: '/orders',
      },
      {
        title: 'Sales Reporting',
        icon: 'mdi-file-chart',
        route_name: '/reports',
      },
      {
        title: 'Users',
        icon: 'mdi-account-circle-outline',
        route_name: '/users',
        permission: {
          roles: [UserRoles.admin, UserRoles.im_user],
        },
      },
      {
        title: 'Announcements',
        icon: 'mdi-alert-decagram',
        route_name: '/announcement',
        permission: {
          rule: CustomPermissions.assignAnnouncement,
          roles: [UserRoles.admin],
        },
      },
      {
        title: 'Promo Management',
        icon: 'mdi-wallet-giftcard',
        route_name: '/promo',
        permission: {
          roles: [UserRoles.admin],
        },
      },
      {
        title: 'Smart Analytics',
        icon: 'mdi-star',
        route_name: '/smart-analytics',
        permission: {
          rule: CustomPermissions.readAnalytics,
        },
      },
      {
        title: 'Ap3 Notification',
        icon: 'mdi-bell',
        route_name: '/notification-manager',
        permission: {
          roles: [UserRoles.admin],
        },
      },
    ],
  }),
  methods: {
    goToPage(nav) {
      if (nav.customRoutes) {
        const route = nav.customRoutes.find((e) => this.user.permissions.scopes.includes(e.rule));
        if (route) {
          return this.$router.push(route.path);
        }
      }
      return this.$router.push(nav.route_name);
    },
    permissionCheck(permission) {
      if (!permission) return true;
      if (permission.roles || permission.rule) {
        let hasAccess = false;
        if (permission.rule) {
          hasAccess = this.user.permissions.scopes.includes(permission.rule);
        }
        if (permission.roles) {
          hasAccess =
            hasAccess || permission.roles.some((role) => this.hasRole(role, permission.strict));
        }
        return hasAccess;
      }

      return true;
    },
    linkClass(route) {
      const css = { active: false };
      if (route === '/') {
        if (this.$route.matched.find((e) => e.path === '')) {
          css.active = true;
        }
        return css;
      }

      if (this.$route.matched.find((e) => e.path.indexOf(route) === 0)) {
        css.active = true;
      }
      return css;
    },
  },
  computed: {
    ...mapGetters('adminPanel', {
      hasRole: 'hasRole',
    }),
    ...mapState('adminPanel', {
      user: 'user',
    }),
  },
};
</script>

<style scoped>
.active {
  background: #0d85eb;
  border-left: 8px solid #38a3fb;
}

.active >>> a.v-list__tile {
  padding-left: 8px;
}

.v-list__tile__action {
  justify-content: center;
  min-width: 48px;
}

.main-title {
  margin-left: 10px;
  text-transform: uppercase;
}

#main-nav {
  overflow-y: scroll;
}
</style>
