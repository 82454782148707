import { TypeOfKds } from '@/constants';
import ID from '@compassdigital/id';
import { cloneDeep } from 'lodash';

export function isDHsite(site) {
  const decoded_id = ID(site.id);
  return (
    (decoded_id && decoded_id.provider === 'dh' && (!site.meta || !site.meta.migrated)) || false
  );
}

export function normalizeSite(site) {
  site = cloneDeep(site);
  if (!site.address) site.address = { country: 'N/A', city: 'N/A' };
  site.name = site.name || 'Untitled';
  site.platform = isDHsite(site) ? 'DH' : 'CDL';
  site.type_of_kds = [];
  site.unit_ids = [];
  site.sector_name = (site.meta && site.meta.sector_name) || '';

  if (site.locations) {
    site.unit_ids = site.locations.map((l) => l.meta && l.meta.unit_id).filter(Boolean);
    if (!site.brands) {
      site.brands = [];
    }
    const siteLocationBrands = [];
    site.locations.map((location) => siteLocationBrands.push(...location.brands));
    site.brands.push(...siteLocationBrands);
  }

  const type_of_kds = [];
  Object.values(site.brands).forEach((brand) => {
    if (brand.meta) {
      if (brand.meta.type_of_kds) {
        if (brand.meta.type_of_kds.nextep) type_of_kds.push(TypeOfKds.nextep.name);
        if (brand.meta.type_of_kds.agilysys) type_of_kds.push(TypeOfKds.agilysys.name);
        if (brand.meta.type_of_kds.volante) type_of_kds.push(TypeOfKds.volante.name);
        if (brand.meta.type_of_kds.cdl) type_of_kds.push(TypeOfKds.cdl.name);
      }
      // Default
      if (brand.meta.has_kds && type_of_kds.length === 0) type_of_kds.push(TypeOfKds.cdl.name);
    }
  });
  if (type_of_kds.length) site.type_of_kds.push(...new Set(type_of_kds));
  else site.type_of_kds.push('N/A');
  return site;
}

export function normalizeSites(sites) {
  return sites.map((site) => normalizeSite(site));
}
