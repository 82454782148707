import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import FileHelperModule from '@/helpers/file-helper-module';
import { IMAGE_ENTITY_TYPES, IMAGE_AGES } from '@/constants';

export default {
  namespaced: true,
  state: {
    newImageMap: (() => {
      try {
        return JSON.parse(localStorage.newImageMap);
      } catch (err) {
        return {};
      }
    })(),
    originalImageObjectMap: {},
  },
  mutations: {
    setNewImageMap(state, payload) {
      state.newImageMap = payload;
      localStorage.setItem('newImageMap', JSON.stringify(state.newImageMap));
    },
    setOriginalImageObjectMap(state, payload) {
      state.originalImageObjectMap = payload;
    },
  },
  actions: {
    async cleanImages({ dispatch, state }, { imageKeyList, type, entities, age }) {
      if (!imageKeyList || !age) throw new Error('could not clean images');
      let deleteImagePromises;
      let filteredEntities;
      switch (age) {
        case IMAGE_AGES.new:
          deleteImagePromises = dispatch('prepareNewImagesForDelete', imageKeyList);
          break;
        case IMAGE_AGES.old:
          if (!type || !entities) throw new Error('could not clean images');
          filteredEntities = FileHelperModule.filterImageEntities(
            type,
            entities,
            state.newImageMap,
          );
          if (filteredEntities.size) {
            deleteImagePromises = dispatch('prepareOldImagesForDelete', {
              type,
              imageKeyList,
              entities: filteredEntities,
            });
          }
          break;
        default:
          break;
      }
      await FileHelperModule.cleanFiles(deleteImagePromises, age, 's3');
    },
    resetImageMaps({ commit }) {
      commit('setNewImageMap', {});
      commit('setOriginalImageObjectMap', {});
    },
    prepareNewImagesForDelete({ state }, imageKeyList) {
      if (!Object.keys(state.newImageMap).length)
        throw new Error('could not store or clean images');
      return Object.values(state.newImageMap).reduce((deleteImagePromises, imageObj) => {
        imageKeyList.forEach((imageKey) => {
          const imageUrl = imageObj?.urls?.[imageKey];
          if (imageUrl) {
            deleteImagePromises.push(
              FileHelperModule.prepareImageForDelete(imageUrl, this._vm.api),
            );
          }
        });
        return deleteImagePromises;
      }, []);
    },
    prepareOldImagesForDelete({ dispatch, state }, { type, entities, imageKeyList }) {
      if (!Object.keys(state.newImageMap).length)
        throw new Error('could not store or clean images');
      switch (type) {
        case IMAGE_ENTITY_TYPES.menu:
          return dispatch('diffMenuItemImagesForDelete', {
            menus: entities,
            imageKeyList,
          });
        default:
          return [];
      }
    },
    diffMenuItemImagesForDelete({ commit, state }, { menus, imageKeyList }) {
      return [...menus].reduce((deleteImagePromises, menu) => {
        if (menu.groups) {
          menu.groups.forEach((group) => {
            if (group.items) {
              group.items.forEach((item) => {
                const mapKey =
                  item.id && (item?.image?.src || item?.image?.src === '')
                    ? `${item.id}:${item.image.src}`
                    : null;
                if (
                  mapKey &&
                  state.newImageMap[mapKey] &&
                  isEqual(item.image.src, state.newImageMap[mapKey]?.urls?.src) &&
                  !isEqual(item.image.src, state.originalImageObjectMap[item.id]?.src)
                ) {
                  /* if the api returns the same image urls as was stored in newImageMap on upload, we can delete the old urls which are stored under item.id in originalImageObjectMap */
                  imageKeyList.forEach((imageKey) => {
                    const imageUrl = state.originalImageObjectMap?.[item.id]?.[imageKey];
                    if (imageUrl) {
                      deleteImagePromises.push(
                        FileHelperModule.prepareImageForDelete(imageUrl, this._vm.api),
                      );
                    }
                  });
                  /* ensure new, in-use image is not included in cleanup */
                  const clonedImageMap = cloneDeep(state.newImageMap);
                  delete clonedImageMap[mapKey];
                  commit('setNewImageMap', cloneDeep(clonedImageMap));
                }
              });
            }
          });
        }
        return deleteImagePromises;
      }, []);
    },
  },
};
