import Vue from 'vue';
import { UserRoles } from '@/constants';

const supportedRoles = Object.values(UserRoles);

export default {
  namespaced: true,
  state: {
    active_user_id: localStorage.active_user_id,
    auth: localStorage.auth ? JSON.parse(localStorage.auth) : {},
    exit_status: '', // prompt for when user gets kicked out of application
    loginEmail: '',
    user: undefined,
    userRealm: '6MNvqeNgGWSLAv4DoQr7CaKzaNGZl5',
    loading: false,
    breadcrumbs: {},
    active_title: '',
    super_admin_permission: '*:*:*',
    notificationPanel: false,
    isLoggedIn: false,
    active_sub_title: '',
    stage: '',
    refresh_auth_promise: undefined,
  },

  mutations: {
    toggleNotificationPanel(state, bool) {
      state.notificationPanel = bool;
    },
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setActiveUserId(state, val) {
      state.active_user_id = val;
      localStorage.active_user_id = val;
    },
    setUser(state, user) {
      state.user = user;
    },
    setExitStatus(state, status) {
      state.exit_status = status;
    },
    setLoginEmail(state, email) {
      state.loginEmail = email;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setTitle(state, title) {
      state.active_title = title;
    },
    setViewTitle(state, { depth, to, title, last = true, breadcrumb, subTitle }) {
      Vue.set(state.breadcrumbs, depth, {
        text: breadcrumb || title,
        exact: true,
        to,
      });
      if (last) {
        state.active_title = title;
        state.active_sub_title = subTitle;
        Object.keys(state.breadcrumbs)
          .filter((e) => e > depth)
          .map((e) => Vue.delete(state.breadcrumbs, e));
      }
    },
    setStage(state, val) {
      state.stage = val;
    },
    setAuth(state, val) {
      state.auth = val;
      localStorage.auth = JSON.stringify(state.auth);
    },
    setRefreshPromise(state, val) {
      state.refresh_auth_promise = val;
    },
  },

  getters: {
    hasRole: (state) => (role, strict = false) => {
      try {
        if (state.user.permissions.scopes.find((s) => s === '*:*:*')) {
          if (strict && role === 'admin') {
            return true;
          }
          if (!strict) {
            return true;
          }
        }

        return state.user.permissions.scopes.map((e) => e.split(':')[0]).includes(`${role}_role`);
      } catch (err) {
        return false;
      }
    },
    getRole: (state) => {
      for (const scope of state.user.permissions.scopes) {
        if (
          scope.includes(UserRoles.sys_admin) ||
          scope.includes(UserRoles.admin) ||
          scope.includes(UserRoles.site_operator) ||
          scope.includes(UserRoles.im_user) ||
          scope.includes(UserRoles.menu_user) ||
          scope.includes(UserRoles.dc_team) ||
          scope.includes(UserRoles.runner)
        ) {
          return scope.split(':')[0];
        }
      }
      return null;
    },
    userHasAnyRole: (state) => (neededRoles = [], strict = false) => {
      try {
        if (!Array.isArray(neededRoles)) return false;
        neededRoles.forEach((neededRole) => {
          if (!supportedRoles.includes(neededRole)) {
            console.warn(`${neededRole} role is not currently supported`);
          }
        });
        const scopes =
          (state.user && state.user.permissions && state.user.permissions.scopes) || [];
        const assignedRoles = scopes
          .map((scope) => {
            const scopeParts = scope.split(':');
            if (!scopeParts[0].includes('_role')) return false;
            return scopeParts[0].replace('_role', '');
          })
          .filter(Boolean);
        const isUserSuperAdmin = scopes.includes('*:*:*');
        if (!strict && isUserSuperAdmin) {
          return true;
        }
        return assignedRoles.some((assignedRole) => neededRoles.includes(assignedRole));
      } catch (err) {
        return false;
      }
    },
    requireAuth: (state) => !state.isLoggedIn,
    isSiteOperator: (state, getters) => getters.hasRole(UserRoles.site_operator, true),
    isImUser: (state, getters) => getters.hasRole(UserRoles.im_user, true),
    isAdmin: (state, getters) => getters.hasRole(UserRoles.admin),
    hasSpecificPermissions: (state) => (permissions = [], strict = false) => {
      try {
        if (!strict && state.user.permissions.scopes.find((s) => s === '*:*:*')) {
          return true;
        }
        return permissions.every((needed) => state.user.permissions.scopes.includes(needed));
      } catch (err) {
        return false;
      }
    },
    hasPermissions: (state) => (permissions = []) => {
      try {
        return permissions.every((needed) => {
          const [required_action, required_service, required_reference] = needed.split(':');

          return state.user.permissions.scopes.find((user_permission) => {
            const [scope_action, scope_service, scope_reference] = user_permission.split(':');

            return (
              permissionMatch(scope_action, required_action) &&
              permissionMatch(scope_service, required_service) &&
              permissionMatch(scope_reference, required_reference)
            );
          });
        });
      } catch (err) {
        console.error('Smth went wrong checking permissions: ', err.message);
        return false;
      }
    },
    getStage: (state) => state.stage,
    getUser: (state) => state.user,
    getPermissions: (state) => state.user.permissions.scopes,
    getIsLoggedIn: (state) => state.isLoggedIn,
    getAppName: (state, getters, rootState) => (currentMultigroupId) => {
      const siteInfo = rootState.sites.multigroupMap[currentMultigroupId];
      return siteInfo?.name ?? '';
    },
  },

  actions: {
    /**
     * Init action to occur on init web app load
     */
    async init({ state, dispatch, commit }) {
      if (state.isLoggedIn) return;
      const access_token = await dispatch('getValidAccessToken');
      if (access_token) {
        try {
          dispatch('sites/fetchMultigroups', null, { root: true }).then(() =>
            dispatch('sites/fetchAll', { nocache: true }, { root: true }),
          );
          await dispatch('getUserInfo');
          commit('setIsLoggedIn', true);
        } catch (err) {
          console.error('could not run init');
        }
      } else {
        dispatch('setLogout');
        commit('setExitStatus', 'Your login has expired, please login again to continue.');
      }
    },

    /**
     * Fetch user information
     */
    async getUserInfo({ state, commit }) {
      if (state.active_user_id) {
        const { data } = await this._vm.api.get(`/user/${state.active_user_id}/permissions`);
        commit('setUser', data);
      }
    },
    async login({ state, commit, dispatch }, { email, password }) {
      const data = await dispatch(
        'users/getUserAuth',
        { email, password, realm: state.userRealm },
        { root: true },
      );
      commit('setAuth', { access: data.access, refresh: data.refresh });
      commit('setActiveUserId', data.user);
      commit('setExitStatus', '');
    },

    setLoading({ commit }, value) {
      commit('setLoading', value);
    },
    setLogout({ dispatch, commit }) {
      commit('setAuth', {});
      commit('setActiveUserId', undefined);
      commit('setUser', undefined);
      commit('setIsLoggedIn', false);
      dispatch('analytics/clearCache', '', { root: true });

      if (window.heap?.loaded) window.heap.resetIdentity();
      delete localStorage.auth;
      delete localStorage.active_user_id;
      delete localStorage.selectedStations;
    },

    async getValidAccessToken({ state, commit, dispatch }) {
      if (state.refresh_auth_promise) return state.refresh_auth_promise;
      const promise = dispatch('getValidAccessTokenCall');
      commit('setRefreshPromise', promise);
      promise.finally(() => {
        commit('setRefreshPromise', undefined);
      });
      return promise;
    },

    async getValidAccessTokenCall({ state, commit, dispatch }) {
      const now = new Date(Date.now() + 30 * 1000);
      if (!state.auth?.access) return '';
      const access_expires = new Date(state.auth?.access?.expires);
      const refresh_expires = new Date(state.auth?.refresh?.expires);
      if (!access_expires || !refresh_expires) return '';
      if (access_expires > now) return state.auth.access?.token;
      // both access/refresh expired
      if (refresh_expires < now) return '';

      try {
        const data = await dispatch(
          'users/postUserAuth',
          {
            refresh_token: state.auth.refresh?.token,
            realm: state.userRealm,
          },
          { root: true },
        );

        commit('setAuth', { access: data.access, refresh: data.refresh });
        return data.access?.token;
      } catch (err) {
        console.error('Retrying auth after error:', err);
        const data = await dispatch(
          'users/postUserAuth',
          { refresh_token: state.auth.refresh?.token, realm: state.userRealm },
          { root: true },
        ).catch((_err) => {
          console.error('Retry auth failed', _err.message);
          return '';
        });
        if (data) {
          commit('setAuth', { access: data.access, refresh: data.refresh });
          return data.access?.token;
        }
        commit('setAuth', {});
        this._vm.$toast.error('Could not refresh auth data, please re-login');
        return '';
      }
    },
  },
};
function permissionMatch(available = '', needed = '') {
  if (!needed) return true;
  return available === needed || available === '*';
}
