import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

// eslint-disable-next-line import/prefer-default-export
export const mergeReactive = function mergeReactive(oldObject, newObject, reactive = true) {
  if (!oldObject && !newObject) return oldObject;
  if (!oldObject || typeof oldObject !== 'object') return newObject;
  if (!newObject || typeof newObject !== 'object') return oldObject;
  Object.keys(newObject).forEach((key) => {
    if (isEqual(oldObject[key], newObject[key])) return;
    const newValue = cloneDeep(newObject[key]);
    if (reactive) {
      Vue.set(oldObject, key, newValue);
    } else {
      oldObject[key] = newValue;
    }
  });
  return oldObject;
};
