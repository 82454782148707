import cloneDeep from 'lodash/cloneDeep';

export default {
  namespaced: true,
  state: {
    all_sectors: [],
    all_sectors_fetched: false,
    active_sector: null,
    active_company: null,
  },
  mutations: {
    setActiveSector(state, sector) {
      state.active_sector = sector;
    },
    setSectors(state, sectors) {
      if (state.all_sectors.length && sectors.length) {
        state.all_sectors.forEach((oldSector) => {
          const newSector = sectors.find((_s) => _s.id === oldSector.id);
          if (newSector && !newSector.companies && oldSector.companies) {
            newSector.companies = oldSector.companies;
          }
        });
      }
      state.all_sectors = sectors.sort((a, b) => a.name && a.name.localeCompare(b.name));
    },
    setSector(state, new_sector) {
      const allSectors = [...state.all_sectors];
      const currentIndex = allSectors.findIndex((s) => s.id === new_sector.id);
      if (currentIndex === -1) {
        allSectors.push(new_sector);
        const updateSector = [...allSectors].sort((a, b) => a.name && a.name.localeCompare(b.name));
        state.all_sectors = updateSector;
      } else {
        const currentSector = allSectors[currentIndex];
        const updateCurrentSector = { ...currentSector, ...new_sector };
        allSectors[currentIndex] = updateCurrentSector;
        state.all_sectors = allSectors;
      }
    },
    setCompany(state, company) {
      const other_sectors = state.all_sectors.filter((s) => s.id !== company.sector);
      const company_sector = state.all_sectors.find((s) => s.id === company.sector) || {
        id: company.sector,
      };
      if (company_sector) {
        if (!company_sector.companies) {
          company_sector.companies = [];
        }
        company_sector.companies = [
          ...company_sector.companies.filter((c) => c.id !== company.id),
          company,
        ].sort((a, b) => a.name && a.name.localeCompare(b.name));
      }
      state.all_sectors = [...other_sectors, company_sector].sort(
        (a, b) => a.name && a.name.localeCompare(b.name),
      );
    },
    setAllSectorsFetchedFlag(state, val) {
      state.all_sectors_fetched = val;
    },
    setActiveCompany(state, company) {
      state.active_company = company;
    },
  },
  getters: {
    getSector: (state) => (sector_id) => state.all_sectors.find((s) => s.id === sector_id) || {},
    getCompany: (state) => (company_id) => {
      const companies = state.all_sectors
        .map((s) => s.companies)
        .flat()
        .filter(Boolean);
      return companies.find((c) => c.id === company_id);
    },
    getMappedSector: (state) => (sector_id) => {
      const sector = state.all_sectors.find((s) => s.id === sector_id);
      if (!sector) {
        return {};
      }
      const companies = (sector && sector.companies) || [];
      return {
        value: sector.id,
        text: sector.name,
        companies: companies.map((c) => ({
          value: c.id,
          text: c.name,
        })),
      };
    },
    mappedSectors: (state) =>
      state.all_sectors.map((s) => ({
        value: s.id,
        text: s.name,
      })),
  },
  actions: {
    async fetchSector(
      { commit, getters },
      {
        sector_id,
        nocache = false,
        expanded = true,
        display_error_toast = true,
        timestamp = new Date().getTime(),
      },
    ) {
      const sector = getters.getSector(sector_id);
      if (
        !nocache &&
        sector &&
        sector.id &&
        sector.companies &&
        sector.companies.length &&
        (sector.expanded === true || expanded === false)
      ) {
        // return cached if it has everything needed and there is no nocache flag
        return sector;
      }
      try {
        const { data } = await this._vm.api.get(`/location/sector/${sector_id}`, {
          params: { nocache, expanded, timestamp },
        });
        if (expanded) {
          data.expanded = true;
        }
        commit('setSector', data);
        return data;
      } catch (err) {
        console.error(err);
        if (display_error_toast) this._vm.$toast.error('Could not fetch companies list');
      }
      return getters.getSector(sector_id);
    },
    async updateSector({ commit, dispatch }, sector) {
      const { data } = await this._vm.api.patch(`/location/sector/${sector.id}`, sector);
      dispatch('getLocationSector', true); // refresh cache
      commit('setSector', data);
    },
    async createSector({ commit, dispatch }, sector) {
      const { data } = await this._vm.api.post('/location/sector', sector);
      dispatch('getLocationSector', true); // refresh cache
      commit('setSector', data);
    },
    async fetchSectors({ commit, state, dispatch }, nocache = false) {
      if (!nocache && state.all_sectors_fetched) {
        // return cached if it has everything needed and there is no nocache flag
        return state.all_sectors;
      }
      try {
        const { data } = await dispatch('getLocationSector', nocache);
        commit('setSectors', data.sectors);
        commit('setAllSectorsFetchedFlag', true);
      } catch (err) {
        console.error('could not fetch sectors', err);
        this._vm.$toast.error('Could not fetch sectors information');
        commit('setSectors', []);
      }
      return state.all_sectors;
    },

    async getLocationSector(state, nocache = false) {
      return this._vm.api.get('/location/sector', {
        params: {
          _provider: 'cdl',
          nocache,
        },
      });
    },
    async createCompany({ commit }, company) {
      const { data } = await this._vm.api.post('/location/company', company);
      commit('setCompany', data);
    },
    async updateCompany({ commit }, company) {
      const { data } = await this._vm.api.patch(`/location/company/${company.id}`, company);
      commit('setCompany', { ...data, locations: company.locations || [] });
    },
    async fetchCompany({ commit, getters }, { id, nocache = false }) {
      const company = getters.getCompany(id);
      if (!nocache && company && company.id && company.locations) {
        // return cached if it has everything needed and there is no nocache flag
        return company;
      }
      const { data } = await this._vm.api.get(`/location/company/${id}`);
      commit('setCompany', { ...data, locations: data.locations || [] });
      return cloneDeep(data);
    },
  },
};
