<template>
  <v-toolbar app>
    <v-btn flat icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('mobileMenuToggle')">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <div v-if="isSiteOperator || isImUser" class="logo">
        <v-img :src="multigroupLogo"></v-img>
      </div>
      <notification-count />
      <v-menu offset-y :nudge-width="150">
        <template v-slot:activator="{ on }">
          <v-btn flat v-on="on">
            <v-layout row wrap>
              <v-flex xs12>{{ fullname }}</v-flex>
            </v-layout>
            <v-icon>mdi-chevron-down-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="logout()">
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import notificationCount from '@/components/notifications/notification-count';

export default {
  components: { notificationCount },
  computed: {
    ...mapState('adminPanel', {
      user: (state) => state.user,
    }),
    ...mapState('sites', ['multigroups']),
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser']),
    ...mapGetters('sites', ['activeMultigroup']),
    fullname() {
      if (this.user && this.user.name) {
        return `${this.user.name.first} ${this.user.name.last}`;
      }
      return '';
    },
    multigroupName() {
      if (this.$route.params.app) {
        const multigroup = this.multigroups.find((e) => e.id === this.$route.params.app);
        return multigroup ? multigroup.name.toLowerCase() : '';
      }

      return this.activeMultigroup && this.activeMultigroup.name
        ? this.activeMultigroup.name.toLowerCase()
        : '';
    },
    multigroupLogo() {
      return this.multigroupName
        ? `https://s3.amazonaws.com/api.compassdigital.org/images/${this.multigroupName}_logo.png`
        : '';
    },
  },
  methods: {
    ...mapMutations('sites', ['clear']),
    ...mapActions('adminPanel', ['setLogout']),
    async logout() {
      try {
        await this.api.delete('user/logout');
      } catch (err) {
        console.error('unable to do a hard logout');
      }

      this.setLogout();
      this.clear();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  margin: 0 1em 0.25em 0;
}
</style>
