import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VNavigationDrawer,
    {
      attrs: {
        absolute: "",
        temporary: "",
        right: "",
        width: "350",
        height: "100vh"
      },
      model: {
        value: _vm.notificationPanelProxy,
        callback: function($$v) {
          _vm.notificationPanelProxy = $$v
        },
        expression: "notificationPanelProxy"
      }
    },
    [
      _c(
        VLayout,
        { attrs: { column: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { staticClass: "header-box" },
            [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      _vm.notificationPanelProxy = false
                    }
                  }
                },
                [_c("notification-count", { attrs: { fetch: false } })],
                1
              ),
              _c(
                VFlex,
                { staticClass: "notification-title", attrs: { shrink: "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "Subtitle-1-Primary-Left",
                      staticStyle: {
                        "font-size": "16.5px",
                        "margin-right": "1em"
                      }
                    },
                    [_vm._v("Recent Updates")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            VFlex,
            _vm._l(this.getRelevantDateNotifications, function(notification) {
              return _c(
                VFlex,
                { key: notification.id },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "", disabled: _vm.isRead(notification) },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  VCard,
                                  _vm._g(
                                    {
                                      staticClass: "notification-card",
                                      class: _vm.isRead(notification)
                                        ? "notification-card--disabled"
                                        : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.markRead(notification)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      VFlex,
                                      { staticClass: "notification-icon" },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: {
                                              color: _vm.iconColor(notification)
                                            }
                                          },
                                          [_vm._v("mdi-alert-decagram")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { staticClass: "card-text-box" },
                                      [
                                        _c(
                                          VFlex,
                                          {
                                            staticClass: "card-text-header-box"
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatIsoToDate(
                                                      notification.release_date ||
                                                        notification.date_modified_date
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatIsoToTime(
                                                      notification.release_date ||
                                                        notification.date_modified
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(VFlex, {
                                          staticClass: "card-text",
                                          domProps: {
                                            innerHTML: _vm._s(notification.text)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Mark as Read")])]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }