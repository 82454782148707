import { IMAGE_ENTITY_TYPES, ALLOWED_BUCKET_NAMES, IMAGE_TEMPLATE_KEYS } from '@/constants';
import splitio from '../store/modules/splitio';

export default {
  createCanvas(src, width, height, crop = false) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    const isThumbnail = canvas.width === 80 && canvas.height === 80;
    if (isThumbnail && splitio?.state?.featureFlags['ap3-item-image-thumbnail']) {
      canvas.width = 480;
      canvas.height = 480;
    }

    if (crop) {
      const { sx, sy, sWidth, sHeight } = this.cropImage(src, canvas);
      context.drawImage(src, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);
    } else {
      context.drawImage(src, 0, 0, width, height);
    }

    return canvas;
  },
  cropImage(src, canvas) {
    const aspectRatio = canvas.width / canvas.height;

    const height = src.naturalWidth / aspectRatio;
    const width = src.naturalHeight * aspectRatio;

    const isSmallerWidth = src.naturalWidth < width;

    const sWidth = isSmallerWidth ? src.naturalWidth : width;
    const sHeight = isSmallerWidth ? height : src.naturalHeight;

    const sx = isSmallerWidth ? 0 : (src.naturalWidth - sWidth) / 2;
    const sy = isSmallerWidth ? (src.naturalHeight - sHeight) / 2 : 0;

    return {
      sx,
      sy,
      sWidth,
      sHeight,
    };
  },
  cleanDataURL(dataURL) {
    const isBase64 = dataURL.indexOf('base64') > -1;
    const target = dataURL.indexOf(',');
    if (!isBase64 || target === -1) return dataURL;
    return dataURL.slice(target + 1);
  },
  buildImageStoragePromises({ imageDataList, api, s3Folder, s3SubDir }) {
    return imageDataList.map(({ imageSubDir, cleanedDataURL }) => {
      return api.post('/file', {
        file_base64: cleanedDataURL,
        file_name: `${s3Folder}/${s3SubDir}/${imageSubDir}:`,
      });
    });
  },
  buildImageStorageObject({ imagePathsList, uploadConfig, storeOriginal, isFromS3, isFromObject }) {
    if (!uploadConfig) return {};
    return Object.keys(uploadConfig).reduce((imageObject, imageKey, index) => {
      if (isFromS3) {
        imageObject[imageKey] = imagePathsList[index]?.data?.url;
      } else if (isFromObject) {
        imageObject[imageKey] = Object.values(imagePathsList)[index];
      }
      /* original will always be last index of imageObject, or undefined */
      if (storeOriginal) {
        if (isFromS3) {
          imageObject.original = imagePathsList[imagePathsList.length - 1]?.data?.url;
        } else if (isFromObject) {
          imageObject.original = Object.values(imageObject)[Object.values(imageObject).length - 1];
        }
      } else {
        imageObject.original = undefined;
      }
      return imageObject;
    }, {});
  },
  buildImageMapEntry(key, payload) {
    if (!key || !payload) throw new Error('could not store image');
    return {
      [key]: payload,
    };
  },
  buildImageMapUrls(type, imageObject) {
    switch (type) {
      case IMAGE_ENTITY_TYPES.menu:
        return {
          [IMAGE_TEMPLATE_KEYS.menu.src]: imageObject.src,
          [IMAGE_TEMPLATE_KEYS.menu.original]: imageObject.sizes.original,
          [IMAGE_TEMPLATE_KEYS.menu.thumbnail_80_80]: imageObject.sizes.thumbnail_80_80,
        };
      default:
        return {};
    }
  },
  filterImageEntities(type, entities, newImageMap) {
    if (!newImageMap) throw new Error('could not store or clean images');
    switch (type) {
      case IMAGE_ENTITY_TYPES.menu:
        return entities.reduce((filteredEntities, entity) => {
          Object.values(newImageMap).forEach((imageObj) => {
            if (entity.id === imageObj.menu) {
              filteredEntities.add(entity);
            }
          });
          return filteredEntities;
        }, new Set());
      default:
        return [];
    }
  },
  prepareImageForDelete(url, api) {
    return this.deleteFile({
      bucket_name: ALLOWED_BUCKET_NAMES.images,
      file_path: url,
      api,
    });
  },
  deleteFile({ bucket_name, file_path, api }) {
    return api.delete('/file', {
      data: {
        bucket_name,
        file_path,
      },
    });
  },
  async cleanFiles(deleteFilePromises, age, host) {
    if (deleteFilePromises?.length) {
      try {
        await Promise.all(deleteFilePromises);
      } catch (err) {
        console.error(`error deleting ${age} images from ${host}`, err);
      }
    }
  },
};
