import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

if (process.env.NODE_ENV !== 'development') {
  if (/^(staging|dev)/.test(window.location.hostname)) {
    Vue.use(VueAnalytics, {
      id: ' UA-137568470-1 ',
    });
  }
}
