/* eslint-disable no-loop-func */

const DEFAULT_STATE = {
  notifications: [],
  notificationsPromise: null,
};
const dateFilterNotifications = (notifications) => {
  return notifications.filter((n) => dateFilter(n));
};

const dateFilter = (notification) => {
  const now = new Date().toISOString();
  const release_date = notification.release_date || notification.date_modified || now;
  return release_date <= now;
};

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations: {
    setNotificationsPromise(state, promise) {
      state.notificationsPromise = promise;
    },
    setNotifications(state, notifications = []) {
      state.notifications = notifications;
    },
    setNotification(state, notification) {
      const notificationIndex = state.notifications.findIndex((n) => n.id === notification.id);
      if (notificationIndex > -1) {
        state.notifications.splice(notificationIndex, 1, notification);
      } else {
        state.notifications.push(notification);
      }
    },
  },
  getters: {
    readNotifications(state) {
      const date_filtered_notifications = dateFilterNotifications(state.notifications);
      return date_filtered_notifications.filter((n) => n.status && n.status.read);
    },
    unreadNotifications(state) {
      const date_filtered_notifications = dateFilterNotifications(state.notifications);
      return date_filtered_notifications.filter((n) => !n.status || !n.status.read);
    },
  },
  actions: {
    async fetchNotifications(
      { commit, state },
      { target, start, end, location } = {
        target: 'admin_panel',
        start: new Date(0),
        end: new Date().toISOString(),
        location: '',
      },
    ) {
      const requestIsPending = state.notificationsPromise && !state.notificationsPromise.finished;
      if (requestIsPending && location === '') return;
      const promise = this._vm.api
        .get(`/notification`, {
          params: {
            realm: 'cdl',
            target: target || 'admin_panel',
            start,
            end,
          },
        })
        .then((res) => {
          commit('setNotifications', res.data.notifications);
        })
        .catch((error) => {
          console.error('Could not get notifications', error);
        })
        .finally(() => {
          promise.finished = true;
        });
      commit('setNotificationsPromise', promise);
    },
    async postNotificationStatus({ commit }, notification) {
      commit('setNotification', notification);
      await this._vm.api.post(`/notification/${notification.id}/status`, {
        status: notification.status,
      });
    },
  },
};
