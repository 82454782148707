import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VNavigationDrawer,
    {
      staticClass: "primary darken-5",
      staticStyle: { "z-index": "100" },
      attrs: {
        app: "",
        dark: "",
        id: "main-nav",
        permanent: _vm.$vuetify.breakpoint.mdAndUp,
        temporary: _vm.$vuetify.breakpoint.smAndDown ? true : !_vm.mini,
        "mini-variant": _vm.mini,
        value: _vm.mobileDrawer
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function($event) {
          _vm.mini = $event
        },
        input: function($event) {
          return _vm.$emit("drawerStatus", $event)
        }
      },
      nativeOn: {
        mouseover: function($event) {
          _vm.mini = false
        },
        mouseleave: function($event) {
          _vm.mini = true
        }
      }
    },
    [
      _c(
        VList,
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            height: "100%"
          }
        },
        [
          _c(
            VListTile,
            [
              _c(VListTileAction, [
                _c("img", {
                  attrs: { src: require("@/assets/logos/cdl-icon-white.png") }
                })
              ]),
              _c(
                VListTileContent,
                [
                  _c(VListTileTitle, { staticClass: "main-title" }, [
                    _vm._v("Admin Panel")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(VListTile, { staticStyle: { height: "30px" } }),
          _vm._l(_vm.pages, function(page, index) {
            return [
              _vm.permissionCheck(page.permission) &&
              _vm.checkVersion(page.version)
                ? _c(
                    VListTile,
                    {
                      key: index,
                      class: _vm.linkClass(page.route_name),
                      attrs: {
                        disabled: _vm.linkClass(page.route_name).active,
                        ripple: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.goToPage(page)
                        }
                      }
                    },
                    [
                      _c(
                        VListTileAction,
                        [_c(VIcon, [_vm._v(_vm._s(page.icon))])],
                        1
                      ),
                      _c(
                        VListTileContent,
                        [_c(VListTileTitle, [_vm._v(_vm._s(page.title))])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _c(VSpacer),
          _c(VListTile, [_vm._v(_vm._s(_vm.version))])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }