import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _vm.loading
        ? _c(
            VLayout,
            {
              class: _vm.justify,
              style: _vm.style,
              attrs: { row: "", wrap: "", "align-center": "" }
            },
            [
              _c(
                VFlex,
                { attrs: { shrink: "" } },
                [
                  _c(VProgressCircular, {
                    attrs: {
                      indeterminate: "",
                      color: "primary",
                      size: _vm.size
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.loading ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }