export default {
  namespaced: true,
  state: {
    reportDates: {
      start: '',
      end: '',
    },
  },
  mutations: {
    setReportDates(state, _reportDates) {
      state.reportDates = _reportDates;
    },
  },
};
