import { CentricOSBaseURL } from '@/constants';

// eslint-disable-next-line import/prefer-default-export
export function centricOSURL() {
  switch (document.location.hostname) {
    case 'adminpanel.compassdigital.org':
      return CentricOSBaseURL.production;
    case 'staging.adminpanel.compassdigital.org':
      return CentricOSBaseURL.staging;
    case 'dev.adminpanel.compassdigital.org':
      return CentricOSBaseURL.dev;
    default:
      return CentricOSBaseURL.local;
  }
}
